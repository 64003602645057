import { Button, Menu, MenuItem, PopoverOrigin } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CountEvent, UserType } from "../tsClasses/CHTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type UserMenuProps = {
  mode: string;
};

const UserMenu2 = (props: UserMenuProps) => {
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const navigate = useNavigate();

  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );

  const userType = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER.userType;
    }
    return null;
  });

  function checkUserAuthLevel() {
    if (userType == UserType.COUNTING) {
      return 10;
    }
    if (userType == UserType.MANAGER) {
      return 20;
    }

    if (userType == UserType.ADMINISTRATOR) {
      return 30;
    }

    if (UserType == null) {
      return 0;
    }
    return 0;
  }

  const handleClickCounts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClickCountsManagment = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClickUsers = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handelCountsMenuOnClose = () => {
    setAnchorEl1(null);
  };

  const handelCountsManagmentMenuOnClose = () => {
    setAnchorEl3(null);
  };

  const handelUsersMenuOnClose = () => {
    setAnchorEl2(null);
  };

  const handelNaviChooseCount = () => {
    navigate("/SelectCountEvent");
    handelCountsMenuOnClose();
  };

  const handleNaviSelctItemToCount = () => {
    navigate("/SelectItemToCount");
    handelCountsMenuOnClose();
  };

  const handelNaviManageCount = () => {
    navigate("/CountLogDataFGrid");
    handelCountsMenuOnClose();
  };

  const handelNaviAddNewCount = () => {
    navigate("/AddCountEvent");
    handelCountsMenuOnClose();
  };

  const handelNaviManageAllCounts = () => {
    navigate("/CountEventsDataGrid2");
    handelCountsMenuOnClose();
  };

  const handelNaviAddUser = () => {
    navigate("/admin/AddUserForm");
    handelCountsMenuOnClose();
  };

  const handelNaviimager = () => {
    navigate("/admin/ManageUsers");
    handelCountsMenuOnClose();
  };

  const handleMangeUsersOnClick = () => {
    navigate("/admin/ManageUsers");
    handelCountsMenuOnClose();
  };

  const encorSettingXs: PopoverOrigin = {
    vertical: "top", // Valid options: "top", "center", "bottom"
    horizontal: "left", // Valid options: "left", "center", "right"
  };

  const transitionSettingXs: PopoverOrigin = {
    vertical: "top", // Valid options: "top", "center", "bottom"
    horizontal: "right", // Valid options: "left", "center", "right"
  };

  const encorSettingMs: PopoverOrigin = {
    vertical: "bottom", // Valid options: "top", "center", "bottom"
    horizontal: "right", // Valid options: "left", "center", "right"
  };

  const transitionSettingMs: PopoverOrigin = {
    vertical: "top", // Valid options: "top", "center", "bottom"
    horizontal: "right", // Valid options: "left", "center", "right"
  };

  const encorSetting: PopoverOrigin =
    props.mode == "xs" ? encorSettingXs : encorSettingMs;
  const transitionSetting: PopoverOrigin =
    props.mode == "xs" ? transitionSettingXs : transitionSettingMs;

  return (
    <>
      {checkUserAuthLevel() >= 20 && (
        <Button
          id="counts-managment-button"
          onClick={handleClickCountsManagment}
          sx={{
            my: 1,
            color: "#00bde0",
            display: "block",
            fontFamily: "Rubik",
            fontWeight: 500,
            fontSize: 18,
            marginLeft: "12px",
            marginRight: "12px",
          }}
          aria-controls={open3 ? "counts-managment-menu" : undefined} // Corrected prop name
          aria-haspopup="true"
          aria-expanded={open3 ? "true" : undefined}
        >
          ניהול ספירות
        </Button>
      )}
      {checkUserAuthLevel() >= 30 && (
        <Button
          id="users-button"
          onClick={handleClickUsers}
          sx={{
            my: 1,
            color: "#00bde0",
            display: "block",
            fontFamily: "Rubik",
            fontWeight: 500,
            fontSize: 18,
            marginLeft: "12px",
            marginRight: "12px",
          }}
          aria-controls={open2 ? "users-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open2 ? "true" : undefined}
        >
          ניהול משתמשים
        </Button>
      )}

      <Button
        id="counts-button"
        onClick={handleClickCounts}
        sx={{
          my: 1,
          color: "#00bde0",
          display: "block",
          fontFamily: "Rubik",
          fontWeight: 500,
          fontSize: 18,
          marginLeft: "12px",
          marginRight: "12px",
        }}
        aria-controls={open1 ? "counts-menu" : undefined} // Corrected prop name
        aria-haspopup="true"
        aria-expanded={open1 ? "true" : undefined}
      >
        ספירה נוכחית
      </Button>

      <Menu
        id="counts-menu"
        anchorEl={anchorEl1}
        open={open1}
        MenuListProps={{ "aria-labelledby": "counts-button" }}
        onClose={handelCountsMenuOnClose}
        anchorOrigin={encorSetting}
        transformOrigin={transitionSetting}
      >
        <MenuItem
          sx={{
            fontFamily: "Rubik",
            fontWeight: 500,
            direction: "rtl",
            color: "#10439F",
            "&:hover": { backgroundColor: "transparent" },
            cursor: "default",
          }}
        >
          {currentCount.countEventName != ""
            ? currentCount.countEventName
            : "לא נבחרה ספירה פעילה"}
        </MenuItem>
        <hr />
        <MenuItem
          onClick={handelNaviChooseCount}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            direction: "rtl",
          }}
        >
          {currentCount.countEventName != "" ? "החלף ספירה" : "בחר ספירה"}
        </MenuItem>
        <MenuItem
          onClick={handelNaviManageCount}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            textAlign: "right",
            direction: "rtl",
          }}
        >
          נתוני ספירה פעילה
        </MenuItem>
        <MenuItem
          onClick={handleNaviSelctItemToCount}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            textAlign: "right",
            direction: "rtl",
          }}
        >
          קבוצת פריטים לספירה
        </MenuItem>
      </Menu>
      <Menu
        id="counts-managment-menu"
        anchorEl={anchorEl3}
        open={open3}
        MenuListProps={{ "aria-labelledby": "counts-managment-button" }}
        onClose={handelCountsManagmentMenuOnClose}
        anchorOrigin={encorSetting}
        transformOrigin={transitionSetting}
      >
        <MenuItem
          onClick={handelNaviManageAllCounts}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            textAlign: "right",
            direction: "rtl",
          }}
        >
          ניהול ספירות
        </MenuItem>
        <MenuItem
          onClick={handelNaviAddNewCount}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            textAlign: "right",
            direction: "rtl",
          }}
        >
          הוסף ספירה חדשה
        </MenuItem>
      </Menu>
      <Menu
        id="users-menu"
        anchorEl={anchorEl2}
        open={open2}
        MenuListProps={{ "aria-labelledby": "users-button" }}
        onClose={handelUsersMenuOnClose}
        anchorOrigin={encorSetting}
        transformOrigin={transitionSetting}
      >
        <MenuItem
          onClick={handleMangeUsersOnClick}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            textAlign: "right",
            direction: "rtl",
          }}
        >
          ניהול משתמשים
        </MenuItem>
        <MenuItem
          onClick={handelNaviAddUser}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            direction: "rtl",
          }}
        >
          הוסף משתמש חדש
        </MenuItem>
        <MenuItem
          onClick={handelNaviimager}
          sx={{
            color: "#0c2d57",
            fontFamily: "Rubik",
            fontWeight: 500,
            direction: "rtl",
          }}
        >
          בדיקה
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu2;
