import { CHUser } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

type ChangePasswordFuncProps = {
    userId:number,
  oldPassword : string,
  newPassword : string,
  userToken: string,
}

const ChangePasswordFunc = async (props : ChangePasswordFuncProps) => {
    console.log("Hello from ChangePasswordFunc");

    const raw = JSON.stringify({
        "id": props.userId,
        "oldPassword": props.oldPassword,
        "newPassword": props.newPassword
      });
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: props.userToken },
      body: raw,
    };

  
    try {
      const response = await fetch(
        apiPath+"/updatePassword",
        requestOptions
      );

      if (response.status == 401) {
        console.log("ChangePasswordFunc: Something went wrong!");
        return "Unauthorized";
      }
      if (response == null) {
        console.log("ChangePasswordFunc: Something went wrong!");
      }
  
      const result = await response.text();
      console.log("ChangePasswordFunc RESULT: " + result);
      return result;
    } catch (error) {
      return "gotErr";
    }
  };
  export default ChangePasswordFunc;