// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingModal_overlay__MgWA9 {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(34, 34, 34, 0.8);
}

.LoadingModal_overlay__inner__KKxuV {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.LoadingModal_overlay__content__yVWr5 {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.LoadingModal_spinner__lyOSI {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: LoadingModal_spin__26xP7 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes LoadingModal_spin__26xP7 {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/LoadingModal.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,QAAQ;EACR,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,uCAAuC;EACvC,sBAAsB;EACtB,sDAAkC;EAClC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".overlay {\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(34, 34, 34, 0.8);\n}\n\n.overlay__inner {\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.overlay__content {\n  left: 50%;\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.spinner {\n  width: 75px;\n  height: 75px;\n  display: inline-block;\n  border-width: 2px;\n  border-color: rgba(255, 255, 255, 0.05);\n  border-top-color: #fff;\n  animation: spin 1s infinite linear;\n  border-radius: 100%;\n  border-style: solid;\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `LoadingModal_overlay__MgWA9`,
	"overlay__inner": `LoadingModal_overlay__inner__KKxuV`,
	"overlay__content": `LoadingModal_overlay__content__yVWr5`,
	"spinner": `LoadingModal_spinner__lyOSI`,
	"spin": `LoadingModal_spin__26xP7`
};
export default ___CSS_LOADER_EXPORT___;
