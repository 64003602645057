import CloseIcon from '@mui/icons-material/Close';
import ImageCropper from "./ImageCropper";
import styles from "./Modal.module.css"; // Import CSS module

type ModalProps = {
  updateAvatar: (imgSrc: string) => void;
  closeModal: () => void;
  
};

const Modal = ({ updateAvatar, closeModal }: ModalProps) => {
  return (
    <div
      className={`${styles.modalBackdrop} fixed inset-0 bg-gray-900 bg-opacity-75 transition-all backdrop-blur-sm`}
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className={`${styles.modalContainer} fixed inset-0 z-10 flex items-center justify-center`}>
        <div className={styles.modalContent}>
          <div className="px-5 py-4 relative">
              <CloseIcon 
               onClick={closeModal}
               sx={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                "&:hover": { color: "#00bde0", cursor: "pointer"} 
              }}    />
            <ImageCropper
              updateAvatar={updateAvatar}
              closeModal={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
