// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .CHeader_c_header1__aE7KW {
    color: #0c2d57;
    text-align: center;
    font-size: 36px;
    font-weight: 500; 
    margin-top: 20px;

  }

  .CHeader_c_header2__D7uB6 {
    color: #0c2d57;
    text-align: center;
    font-size: 26px;
    font-weight: 450; 
    margin-top: 10px;
  }
  
  .CHeader_c_header3__qhSPL {
    color: #0c2d57;
    text-align: center;
    font-size: 20px;
    font-weight: 400; 
    margin-top: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/button/CHeader.module.css"],"names":[],"mappings":"EAAE;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;;EAElB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":["  .c_header1 {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 36px;\n    font-weight: 500; \n    margin-top: 20px;\n\n  }\n\n  .c_header2 {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 26px;\n    font-weight: 450; \n    margin-top: 10px;\n  }\n  \n  .c_header3 {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 20px;\n    font-weight: 400; \n    margin-top: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c_header1": `CHeader_c_header1__aE7KW`,
	"c_header2": `CHeader_c_header2__D7uB6`,
	"c_header3": `CHeader_c_header3__qhSPL`
};
export default ___CSS_LOADER_EXPORT___;
