// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserCard_maindiv__VZ8\\+7 {
    margin: auto;
    margin-bottom: 15px;
    padding-top: 25px;
    height: 12rem;
    width: 10rem;
    align-items: center;
    box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);
    border-radius: 6px;
    color: #0c2d57;
    background-color: inherit;
    cursor: pointer;

  }
  
  .UserCard_user_name__jT\\+5O {
    font-size: 1.0rem;
    font-weight: 700;
    width: calc (inherit-0.5rem);
  }

  .UserCard_user_type__8V9GB {
    font-size: 0.75rem;
    font-weight: 500;
    width: calc (inherit-0.5rem);
  }

  @media (min-width: 768px) {
    .UserCard_modal__VozgQ {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/cards/UserCard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,gDAAgD;IAChD,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,eAAe;;EAEjB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;EAC9B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;EAC9B;;EAEA;IACE;MACE,uBAAuB;MACvB,YAAY;IACd;EACF","sourcesContent":[".maindiv {\n    margin: auto;\n    margin-bottom: 15px;\n    padding-top: 25px;\n    height: 12rem;\n    width: 10rem;\n    align-items: center;\n    box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);\n    border-radius: 6px;\n    color: #0c2d57;\n    background-color: inherit;\n    cursor: pointer;\n\n  }\n  \n  .user_name {\n    font-size: 1.0rem;\n    font-weight: 700;\n    width: calc (inherit-0.5rem);\n  }\n\n  .user_type {\n    font-size: 0.75rem;\n    font-weight: 500;\n    width: calc (inherit-0.5rem);\n  }\n\n  @media (min-width: 768px) {\n    .modal {\n      left: calc(50% - 20rem);\n      width: 40rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maindiv": `UserCard_maindiv__VZ8+7`,
	"user_name": `UserCard_user_name__jT+5O`,
	"user_type": `UserCard_user_type__8V9GB`,
	"modal": `UserCard_modal__VozgQ`
};
export default ___CSS_LOADER_EXPORT___;
