import { useRef } from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CountEvent, CountLogDTO } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import CHeader from "../button/CHeader";
import CusatomDataGridToolBar from "../button/CusatomDataGridToolBar";

export default function CountLogDataFGridSum() {
  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const currentCountLogs2: CountLogDTO[] | null = useSelector(
    (state: RootState) => state.data.currentCountLogs
  );

  type GroupedItem = {
    itemSKU: string;
    itemDescription: string;
    totalQty: number;
  };

  function groupBySKUAndDescription(countLogs: CountLogDTO[]): GroupedItem[] {
    const groupedItemsMap = new Map<string, GroupedItem>();

    // Iterate through each CountLogDTO
    countLogs.forEach((log) => {
      const { itemSKU, itemDescription, qty } = log;
      const key = `${itemSKU}-${itemDescription}`;

      // If item already exists in the map, update its total quantity
      if (groupedItemsMap.has(key)) {
        const existingItem = groupedItemsMap.get(key);
        if (existingItem) {
          existingItem.totalQty += qty;
          groupedItemsMap.set(key, existingItem);
        }
      } else {
        // If item doesn't exist in the map, add it
        groupedItemsMap.set(key, {
          itemSKU,
          itemDescription,
          totalQty: qty,
        });
      }
    });

    // Convert map values to array
    const groupedItemsArray = Array.from(groupedItemsMap.values());

    return groupedItemsArray;
  }

  const initialRows: GridRowsProp = groupBySKUAndDescription(
    currentCountLogs2
  ).map((x) => ({
    itemSKU: x.itemSKU,
    itemDescription: x.itemDescription,
    qty: x.totalQty,
  }));

  const [rows, setRows] = useState(initialRows);

  const gridRef = useRef(null);

  const columns: GridColDef[] = [
    { field: "itemSKU", headerName: 'מק"ט', width: 225, align: "right" },
    {
      field: "itemDescription",
      headerName: "תיאור פריט",
      width: 300,
      align: "right",
    },
    {
      field: "qty",
      headerName: "כמות",
      width: 65,
      align: "center",
      headerAlign: "center",
    },
  ];

  const cusatomDataGridToolBarProps = {
    dataArrey:  groupBySKUAndDescription(currentCountLogs2),
    worksheetName: "CountLogsSumBySKU",
    fileName: "Count Summery",
  };

  function CustomToolbar() {
    return CusatomDataGridToolBar(cusatomDataGridToolBarProps);
  }

  return (
    <div
      style={{
        width: "80%",
        padding: "0px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <CHeader type={1}>סיכום ספירה</CHeader>
      <CHeader type={2}>{currentCount.countEventName}</CHeader>
      <div id="data-grid">
        <DataGrid
          ref={gridRef}
          slots={{ toolbar: CustomToolbar }}
          columns={columns}
          rows={rows}
          getRowId={(row) => row.itemSKU}
          autoHeight
          sx={{
            boxShadow: 2,
            align: "center",
            flexGrow: 1,
          }}
        />
      </div>
    </div>
  );
}
