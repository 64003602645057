// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_home_header__eniT3{
    margin-top: 50px;
    font-size: 32px;
    color: #0c2d57;


}

.HomePage_cover_image__rQLG\\+ {
    width: 40%;
    padding: 8vh;
    
    
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;;;AAGlB;;AAEA;IACI,UAAU;IACV,YAAY;;;AAGhB","sourcesContent":[".home_header{\n    margin-top: 50px;\n    font-size: 32px;\n    color: #0c2d57;\n\n\n}\n\n.cover_image {\n    width: 40%;\n    padding: 8vh;\n    \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_header": `HomePage_home_header__eniT3`,
	"cover_image": `HomePage_cover_image__rQLG+`
};
export default ___CSS_LOADER_EXPORT___;
