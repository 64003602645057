import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/LoginForm";
import { CountEventsList } from "./pages/CountEventsList";
import { FrameProfileCountForm } from "./pages/FrameProfileCountForm";
import { ExtendersCountForm } from "./pages/ExtendersCountForm";
import { HeadsCountForm } from "./pages/HeadsCountForm";
import { OtherProductsCountForm } from "./pages/OtherProductsCountForm";
import GeneralCountForm from "./pages/GeneralCountForm";
import AddUserForm from "./pages/AddUserForm";
import FrameSetCountForm from "./pages/FrameSetCountForm";
import HomePage from "./pages/HomePage";
import Unauthorized from "./pages/Unauthorized";
import SessionTO from "./pages/SessionTO";
import ErrorPage from "./pages/ErrorPage";
import { UserType } from "./tsClasses/CHTypes";
import RequireAuth from "./pages/RequireAuth";
import ResponsiveAppBar from "./navbar/ResponsiveAppBar";
import SelectMainProductGroup from "./pages/SelectMainProductGroup";
import AddCountEventForm from "./pages/AddCountEventForm";
import CurrentCountManagment from "./pages/CurrentCountLogsTable";
import CountLogDataFGridSum from "./pages/CountLogDataFGridSum";
import CountLogDataFGrid from "./pages/CountLogDataFGrid";
import CountEventsDataGrid from "./pages/CountEventsDataGrid";
import DemoGrid06 from "./pages/CountLogDataFGridExtended";
import CountLogDataFGridExtended from "./pages/CountLogDataFGridExtended";
import CountEventsDataGrid2 from "./pages/CountEventsDataGrid2";
import CoverCountForm from "./countForms/CoverCountForm";
import "react-image-crop/dist/ReactCrop.css";
import Profile from "./button/ProfileAvatar";
import DemoRFC from "./cards/DemoRFC";
import UpdateUserProfile from "./pages/UpdateUserProfile22";
import ChangePasswordForm from "./pages/ChangePasswordForm";
import { ManageUsers } from "./pages/usersManagment/ManageUsers";

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar></ResponsiveAppBar>
      <Routes>
        //Public Routes
        <Route path="/" element={<HomePage />} />
        <Route path="/Unauthorized" element={<Unauthorized />} />
        <Route path="/SessionTO" element={<SessionTO />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/login" element={<Login />} />
        //CountEvent & Forms
        <Route element={<RequireAuth authUserType={UserType.COUNTING} />}>
          <Route path="/SelectCountEvent" element={<CountEventsList />} />
          <Route
            path="/SelectItemToCount"
            element={<SelectMainProductGroup />}
          />
          // Count Forms Routes
          <Route path="/CoverCountForm" element={<CoverCountForm />} />
          <Route
            path="/FrameProfileCountForm"
            element={FrameProfileCountForm()}
          />
          <Route path="/FrameSetCountForm" element={FrameSetCountForm()} />
          <Route path="/GenralCountForm" element={GeneralCountForm()} />
          <Route path="/ExtendersCountForm" element={ExtendersCountForm()} />
          <Route path="/HeadsCountForm" element={HeadsCountForm()} />
          <Route path="/CoversCountForm" element={<CoverCountForm />} />
          <Route
            path="/CurrentCountManagment"
            element={CurrentCountManagment()}
          />
          <Route path="/CountLogDataFGrid" element={<CountLogDataFGrid />} />
          <Route
            path="/DataGridDemo2"
            element={<CountLogDataFGridExtended />}
          />
          <Route path="/UpdateProfile" element={<UpdateUserProfile />} />
          true
          <Route
            path="/CountLogDataFGridSum"
            element={<CountLogDataFGridSum />}
          />
          <Route
            path="/CountEventsDataGrid"
            element={<CountEventsDataGrid />}
          />
          <Route path="/DemoGrid06" element={<DemoGrid06 />} />
          <Route
            path="/OtherProductsCountForm"
            element={OtherProductsCountForm()}
          />
          <Route path="/AddCountEvent" element={<AddCountEventForm />} />
          <Route
            path="/CountEventsDataGrid2"
            element={<CountEventsDataGrid2 />}
          />
        </Route>
        <Route path="/ChangePasswordForm" element={<ChangePasswordForm />} />
        /Admin Routes
        <Route path="/admin/AddUserForm" element={<AddUserForm />} />
        <Route path="/admin/ManageUsers" element={<ManageUsers />} />
      </Routes>
      <br />
      <br />
    </div>
  );
}

export default App;
