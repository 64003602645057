import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import { CheckLogIn } from "../functions/CheckLogIn";
import { logout } from "../store/userSlice";
import { clearAllDataLists } from "../store/dataSlice";
import { UserType } from "../tsClasses/CHTypes";
import { CheckActivity } from "../functions/CheckActivity";

interface RequireAuthProps {
  authUserType: UserType;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ authUserType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userToken = useSelector((state: RootState) => {
    if (state.user.userToken) {
      return state.user.userToken;
    }
    return "";
  });

  const userType = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER.userType;
    }
    return null;
  });

  CheckLogIn(userToken).then((res) => {
    if (res == false) {
      navigate("/SessionTO");
      dispatch(logout());
      dispatch(clearAllDataLists());
    }
  });

  const location = useLocation();

  if (userType == null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  function checkAuthLevel(role: UserType) {
    if (role === UserType.COUNTING) {
      return 10;
    }
    if (role === UserType.MANAGER) {
      return 20;
    }

    if (role === UserType.ADMINISTRATOR) {
      return 30;
    }

    if (role == null) {
      return 0;
    }
    return 0;
  }

  const x = checkAuthLevel(userType);
  const y = checkAuthLevel(authUserType);

  console.log("checkAuthLevel User" + x);
  console.log("checkAuthLevel UserType" + y);

  if (checkAuthLevel(userType) >= checkAuthLevel(authUserType)) {
    return <Outlet />;
  }

  return <Navigate to="/Unauthorized" state={{ from: location }} replace />;
};

export default RequireAuth;
