import { CountEvent, CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

type AddCountEventFuncProps = {
  countEvent: CountEvent;
  userToken: string;
};

const AddCountEventFunc = async (props: AddCountEventFuncProps) => {
  console.log(props);
  console.log("AddCountEventFunc: token" + props.userToken);

  const dataToSend = props.countEvent;

  console.log(dataToSend);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: props.userToken },
    body: JSON.stringify(dataToSend),
  };
  try {
    const response = await fetch(apiPath + "/addCountEvent", requestOptions);
    if (response.status == 401) {
      console.log("AddCountEventFunc: Something went wrong!");
      return "Unauthorized";
    }
    if (response == null) {
      console.log("AddCountEventFunc: Something went wrong!");
    }

    const result = await response.text();
    console.log("AddCountEventFunc RESULT: " + result);
    return result;
  } catch (error) {
    return "gotErr";
  }
};

export default AddCountEventFunc;
