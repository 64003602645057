import React, { useRef, useState, ChangeEvent } from "react";
import ReactCrop, {
  Crop,
  makeAspectCrop,
  centerCrop,
  PercentCrop,
  convertToPixelCrop,
} from "react-image-crop";
import setCanvasPreview from "../functions/setCanvasPreview";
import { ButtonBase } from "@mui/material";
import ButtonCo from "../modals/ButtonCo";


const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

interface ImageCropperProps {
  closeModal: () => void;
  updateAvatar: (dataUrl: string) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  closeModal,
  updateAvatar,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop>();
  const [error, setError] = useState<string>("");

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e: Event) => {
        if (error) setError("");
        const target = e.currentTarget as HTMLImageElement;
        const { naturalWidth, naturalHeight } = target;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const newCrop: PercentCrop = {
      unit: "%",
      width: cropWidthInPercent,
      x: 0,
      y: 0,
      height: 0
    };

    const aspectCrop = makeAspectCrop(newCrop, ASPECT_RATIO, width, height);
    const centeredCrop = centerCrop(aspectCrop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropImage = () => {
    if (!imgRef.current || !previewCanvasRef.current || !crop) return;

    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );

    const dataUrl = previewCanvasRef.current.toDataURL();
    updateAvatar(dataUrl);
    closeModal();
  };



  return (
    <>
      <label style={{ marginBottom: "0.75rem", display: "inline-block" }}>
        <span style={{ border: "2px solid #00bde0",borderRadius: "8px", display: "inline-block", padding: "6px 12px", cursor: "pointer"}}
>בחר תמונת פרופיל</span>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
      </label>
      {error && (
        <p style={{ color: "#ef4444", fontSize: "0.75rem" }}>{error}</p>
      )}
      {imgSrc && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "70vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <br/>
          <ButtonCo
            onClick={handleCropImage}
          >
            Crop Image
          </ButtonCo>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: "150px",
            height: "150px",
          }}
        />
      )}
    </>
  );
};

export default ImageCropper;
