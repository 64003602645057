import React from "react";
import ReactDOM from "react-dom";
import classes from "./LoadingModal.module.css";

interface LoadingModalProps {
  open: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ open }) => {
  if (!open) {
    return null;
  }

  const portalElement = document.getElementById("portal");
  if (!portalElement) {
    return null; // Return null if portal element is not found
  }

  return ReactDOM.createPortal(
    <>
      <div className={classes.overlay}>
        <div className={classes.overlay__inner}>
          <div className={classes.overlay__content}>
            <span className={classes.spinner}></span>
          </div>
        </div>
      </div>
    </>,
    portalElement
  );
};

export default LoadingModal;

