import React from "react";
import ReactDOM from "react-dom";
import classes from "./ErrorModal.module.css";
import ButtonCo from "./ButtonCo";
import Card from "./Card";

interface BasicModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

export default function BasicModal({
  open,
  onClose,
  onConfirm,
  title,
  message,
}: BasicModalProps): JSX.Element | null {
  if (!open) {
    console.log("Here we fall first");
    return null;
  }

  const portalElement = document.getElementById("portal");

  if (!portalElement) {
    console.log("Here we fall second");
    return null; // Return null if portal element is not found
  }
  return ReactDOM.createPortal(
    <>
      <div>
        <div className={classes.backdrop} onClick={onConfirm}>
          <Card className={classes.modal}>
            <header className={classes.header}>
              <h2>{title}</h2>
            </header>
            <div className={classes.content}>
              <p>{message}</p>
            </div>
            <footer className={classes.actions}>
              <ButtonCo onClick={onClose}>OK</ButtonCo>
            </footer>
          </Card>
          ,
        </div>
      </div>
    </>,
    portalElement
  );
}
