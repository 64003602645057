// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main_div{
    align-items: center;

}

.RadioGroupCo_radio__nwG5\\+ {
    display: flex;
    overflow: hidden;
    border-radius: 25px;
    box-shadow: 0 0 5px rgba(0,0,0,0.25);
    height: 50px;


}

.RadioGroupCo_radio__input__AkQjE {
    display: none;
}

.RadioGroupCo_radio__label__J0HOB {
    padding: 14px 14px;
    font-size: 16px;
    color: white;
    background: #0c2d57;
    cursor: pointer;
    /* Set a fixed width */
    width: 100px; /* Adjust this value as needed */
    text-size-adjust: auto;
    white-space: nowrap;

}

.RadioGroupCo_radio__label__J0HOB:not(:last-of-type) {
    border-right: 1px solid #051831;
}

.RadioGroupCo_radio__input__AkQjE:not(:first-of-type):not(:last-of-type):checked + .RadioGroupCo_radio__label__J0HOB {
    background: #051831;
}

`, "",{"version":3,"sources":["webpack://./src/modals/RadioGroupCo.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,oCAAoC;IACpC,YAAY;;;AAGhB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,YAAY,EAAE,gCAAgC;IAC9C,sBAAsB;IACtB,mBAAmB;;AAEvB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["main_div{\n    align-items: center;\n\n}\n\n.radio {\n    display: flex;\n    overflow: hidden;\n    border-radius: 25px;\n    box-shadow: 0 0 5px rgba(0,0,0,0.25);\n    height: 50px;\n\n\n}\n\n.radio__input {\n    display: none;\n}\n\n.radio__label {\n    padding: 14px 14px;\n    font-size: 16px;\n    color: white;\n    background: #0c2d57;\n    cursor: pointer;\n    /* Set a fixed width */\n    width: 100px; /* Adjust this value as needed */\n    text-size-adjust: auto;\n    white-space: nowrap;\n\n}\n\n.radio__label:not(:last-of-type) {\n    border-right: 1px solid #051831;\n}\n\n.radio__input:not(:first-of-type):not(:last-of-type):checked + .radio__label {\n    background: #051831;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `RadioGroupCo_radio__nwG5+`,
	"radio__input": `RadioGroupCo_radio__input__AkQjE`,
	"radio__label": `RadioGroupCo_radio__label__J0HOB`
};
export default ___CSS_LOADER_EXPORT___;
