
import { apiPath } from "./GeneralFunc";

export const CheckLogIn = async (userToken: string) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: userToken },
  };

  const response = await fetch(
    apiPath+"/checkAuth",
    requestOptions
  );
  if (response == null || !response.ok) {


    return false;

  }

  return true;
};
