import { Typography, TextField, InputLabel, Select, MenuItem, SelectChangeEvent, FormControl, Grid, IconButton, Avatar } from "@mui/material";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonCo from "../modals/ButtonCo";
import AddUserFunc from "../functions/AddUserFunc";
import { CHUser, UserType, getUserTypeFromString } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import LoadingModal from "../modals/LoadingModal";
import SuccessModal from "../modals/SuccessModal";
import BasicModal from "../modals/BasicModal";
import CHeader from "../button/CHeader";
import classes from "../countForms/CountForms.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ProfileAvatar from "../button/ProfileAvatar";
import uploadAvatar from "../functions/UploadAvatarFunc";

export default function AddUserForm() {
  console.log("Hello from AddUserForm");

  const [isEmailValid, setisEmailValid] = useState(false);
  const [isFirstNameValid, setisFirstNameValid] = useState(false);
  const [isLastNameValid, setisLastNameValid] = useState(false);
  const [isPasswordValid, setisPasswordValid] = useState(false);
  const [isUserTypeValid, setisUserTypeValid] = useState(false);
  const [isMobilePhoneValid, setisMobilePhoneValid] = useState(false);
  const [isEmailBlur, setisEmailBlur] = useState(false);
  const [isFirstNameBlur, setisFirstNameBlur] = useState(false);
  const [isLastNameBlur, setisLastNameBlur] = useState(false);
  const [isPasswordBlur, setisPasswordBlur] = useState(false);
  const [isMobilePhoneBlur, setisMobilePhoneBlur] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const [isSuccessModallOpen, setisSuccessModallOpen] = useState(false);

  const navigate = useNavigate();
  const allInputsValid = isEmailValid && isFirstNameValid && isLastNameValid && isPasswordValid;

  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  const handleEmailChange = (e: { target: { value: string; name: any; }; }) => {
    if (isEmail(e.target.value)) {
      setisEmailValid(true);
    } else {
      setisEmailValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(isEmailValid);
  };

  const handleFirstNameChange = (ev: { target: { value: string | any[]; name: any; }; }) => {
    if (ev.target.value.length >= 2) {
      setisFirstNameValid(true);
    } else {
      setisFirstNameValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
    console.log(isFirstNameValid);
  };

  
  const handleLastNameChange = (ev: { target: { value: string | any[]; name: any; }; }) => {
    if (ev.target.value.length >= 2) {
      setisLastNameValid(true);
    } else {
      setisLastNameValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
    console.log(isLastNameValid);
  };

  const handleSelectUserTypeChange = (ev: { target: { value: string ; name: any; }; }) => {
    if (ev.target.value.length >= 2) {
      setisUserTypeValid(true);
    } else {
      setisUserTypeValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: getUserTypeFromString(ev.target.value),
    }));
    console.log(isUserTypeValid);
  };

  const handlePasswordChange = (ev: { target: { value: string | any[]; name: any; }; }) => {
    if (ev.target.value.length > 5) {
      setisPasswordValid(true);
      console.log(isPasswordValid);
    } else {
      setisPasswordValid(false);
      console.log(isPasswordValid);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
    console.log(isPasswordValid);
  };

  const rgxZIP= /^[0-9]*$/;

  const handleMobilePhoneChange = (ev: { target: { value: string ; name: any; }; }) => {
    if (ev.target.value.length >= 10) {
      setisMobilePhoneValid(true);
    } else {
      setisMobilePhoneValid(false);
    }
    
    if (rgxZIP.test(ev.target.value) && ev.target.value.length < 11) {

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }
    console.log(isPasswordValid);
  };

  const handleAvatarChange = (imageLink : string) => {
    setInputs((prevState) => ({
      ...prevState,
      avatar: imageLink,
    }));

    console.log(imageLink);
  };
  

  const [inputs, setInputs] = useState({
    userId: 0,
    firstName: "",
    lastName:"",
    userType:UserType.COUNTING,
    email: "",
    mobilePhone:"",
    password: "",
    avatar:"",
    active:false,
  });

  function delay(ms: number | undefined) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handelOnSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (allInputsValid) {
      setisLoadingModalOpen(true);
      console.log(inputs.avatar);
      const userToadd : CHUser = {
        userId: 0,
        password: inputs.password,
        email: inputs.email,
        userType: inputs.userType,
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        mobilePhone: inputs.mobilePhone,
        avatar: inputs.avatar,
        active: true,
      }


      let propsToSend =  {
        userToAdd: userToadd,
        userToken: userToken!
      }

      AddUserFunc(propsToSend).then(async (res) => {
        setisLoadingModalOpen(false);
        if (!res.includes("Error") || res == "gotErr") {
              setisSuccessModallOpen(true);
              await delay(1500);
              setisSuccessModallOpen(false);
              setInputs({
                userId: 0,
                firstName: "",
                lastName:"",
                userType:UserType.COUNTING,
                email: "",
                mobilePhone:"",
                password: "",
                avatar:"",
                active:false,
              });
              navigate("/");
        }

        if (res.includes("Error")) {
          console.log("AddUserForm=>handelOnSubmit=> res=Error");
          let error = JSON.parse(res);
          let errorMSG = error.value;
          setErrorTitle("Error");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "Unauthorized") {
          console.log("AddUserForm=>handelOnSubmit=> res=Error");
          let errorMSG = "Unauthorized Action";
          setErrorTitle("Unauthorized");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "gotErr") {
          setErrorTitle("Server issues");
          setErrorMessage("Please try again in few minutes");
          setisErrorModalOpen(true);
          console.log(res);
        }
      });
    }
  };

  return (
    <div className={classes.main_form_div}>
      <LoadingModal open={isLoadingModalOpen}></LoadingModal>
      <SuccessModal open={isSuccessModallOpen} />
      <BasicModal
        open={isErrorModalOpen}
        onClose={() => setisErrorModalOpen(false)}
        onConfirm={() => setisErrorModalOpen(false)}
        title={errorTitle}
        message={errorMessage}
      />
      <CHeader type={2} >הוספת משתמש חדש</CHeader>
    <br></br>
    <ProfileAvatar onChange={handleAvatarChange}/>
    <br></br>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
        <TextField
          value={inputs.firstName}
          error={isFirstNameBlur && isFirstNameValid === false}
          onBlur={() => setisFirstNameBlur(true)}
          onChange={handleFirstNameChange}
          name="firstName"
          type="text"
          helperText="(נדרש)"
          label="שם פרטי"
          variant="outlined"
        />
            </FormControl>
              </Grid>
      <Grid item xs={12}>
                <FormControl fullWidth>
        <TextField
          value={inputs.lastName}
          error={isLastNameBlur && isLastNameValid === false}
          onBlur={() => setisLastNameBlur(true)}
          name="lastName"
          onChange={handleLastNameChange}
          type="text"
          helperText="(נדרש)"
          label="שם משפחה"
          variant="outlined"
        />
         </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
        <TextField
          error={isEmailBlur && isEmailValid === false}
          onBlur={() => setisEmailBlur(true)}
          value={inputs.email}
          name="email"
          placeholder=""
          helperText="(נדרש)"
          onChange={handleEmailChange}
          label="כתובת דואל"
          variant="outlined"
        />
       </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>

<TextField
          error={isPasswordBlur && isPasswordValid === false}
          value={inputs.password}
          name="password"
          onBlur={() => setisPasswordBlur(true)}
          onChange={handlePasswordChange}
          type={showPassword ? "text" : "password"}
          label="סיסמה"
          helperText="(נדרש - לכל הפחות 6 תווים)"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={() => setshowPassword(true)}
                onMouseUp={() => setshowPassword(false)}
                onMouseLeave={() => setshowPassword(false)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
        <InputLabel id="userTypeSelect-label">תפקיד</InputLabel>
              <Select
                label="תפקיד"
                labelId="userTypeSelect-label"
                id="userTypeSelect"
                name="userType"
                value={inputs.userType}
                onChange={handleSelectUserTypeChange}
                variant="outlined"
              >
                <MenuItem value="" style={{ color: "#FA7070" }}>נקה בחירה</MenuItem>
                <MenuItem value="ADMINISTRATOR">Administrator</MenuItem>
                <MenuItem value="MANAGER">מנהל ספירה</MenuItem>
                <MenuItem value="COUNTING">מבצע ספירה</MenuItem>
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
        <TextField
          value={inputs.mobilePhone}
          error={isMobilePhoneBlur && isMobilePhoneValid === false}
          onBlur={() => setisLastNameBlur(true)}
          name="mobilePhone"
          onChange={handleMobilePhoneChange}
          type="text"
          helperText="(נדרש)"
          label="טלפון נייד"
          variant="outlined"
        />
         </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ButtonCo
                  type={"submit"}
                  onClick={handelOnSubmit}
                  disabled={!allInputsValid}
                >
                הוסף משתמש
                </ButtonCo>
        </Grid>
      </Grid>
    </div>
  );
}
