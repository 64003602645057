// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectUser_main_list_div__iO7mu {
  margin:auto;
  width: 50%
}

.SelectUser_formHeader__YHhnu {
  color: #0c2d57;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-top: 3vh;
}

.SelectUser_no_items_div__1iU13 {
  margin-top: 4vh;
  text-align: center;
  font-size: 18px;
  color: #555;
}

.SelectUser_div_item__Kw-oD {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 35px;
}

.SelectUser_user-card__CsgCQ {
  width: calc(25% - 20px); /* Four items per row on larger screens */
  margin-right: 20px; /* Adjust spacing between items */
  margin-bottom: 20px; /* Adjust spacing between rows */
}

@media (max-width: 1200px) {
  .SelectUser_user-card__CsgCQ {
      width: calc(33.33% - 20px); /* Three items per row on medium screens */
  }
}

@media (max-width: 800px) {
  .SelectUser_user-card__CsgCQ {
      width: calc(50% - 20px); /* Two items per row on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/usersManagment/SelectUser.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,uBAAuB,EAAE,yCAAyC;EAClE,kBAAkB,EAAE,iCAAiC;EACrD,mBAAmB,EAAE,gCAAgC;AACvD;;AAEA;EACE;MACI,0BAA0B,EAAE,0CAA0C;EAC1E;AACF;;AAEA;EACE;MACI,uBAAuB,EAAE,yCAAyC;EACtE;AACF","sourcesContent":[".main_list_div {\n  margin:auto;\n  width: 50%\n}\n\n.formHeader {\n  color: #0c2d57;\n  text-align: center;\n  font-size: 32px;\n  font-weight: 600;\n  margin-top: 3vh;\n}\n\n.no_items_div {\n  margin-top: 4vh;\n  text-align: center;\n  font-size: 18px;\n  color: #555;\n}\n\n.div_item {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  margin: 35px;\n}\n\n.user-card {\n  width: calc(25% - 20px); /* Four items per row on larger screens */\n  margin-right: 20px; /* Adjust spacing between items */\n  margin-bottom: 20px; /* Adjust spacing between rows */\n}\n\n@media (max-width: 1200px) {\n  .user-card {\n      width: calc(33.33% - 20px); /* Three items per row on medium screens */\n  }\n}\n\n@media (max-width: 800px) {\n  .user-card {\n      width: calc(50% - 20px); /* Two items per row on smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_list_div": `SelectUser_main_list_div__iO7mu`,
	"formHeader": `SelectUser_formHeader__YHhnu`,
	"no_items_div": `SelectUser_no_items_div__1iU13`,
	"div_item": `SelectUser_div_item__Kw-oD`,
	"user-card": `SelectUser_user-card__CsgCQ`
};
export default ___CSS_LOADER_EXPORT___;
