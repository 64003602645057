import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CountEvent, CountLogDTO, MainCountGroup } from "../tsClasses/CHTypes";

type InitialState = {
  activeCountEvents: CountEvent[];
  currentCount: CountEvent;
  currentCountLogs: CountLogDTO[];
};

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    activeCountEvents: [],
    currentCount: {
      countEventId: 0,
      countEventName: "",
      countEventDate:  new Date().getTime(),
      itemsList: [],
      active: false,
    },
    currentCountLogs: [],
  },
  reducers: {
    setActiveCountEvents: (state, action) => {
      console.log("readux activeCountEvents in action");
      state.activeCountEvents = action.payload;
    },
    setCurrentCount: (state, action) => {
      console.log("redux currentCount");
      state.currentCount = action.payload;
    },

    setCurrentCountLogs: (state, action) => {
      console.log("redux currentCountLogs");
      state.currentCountLogs = action.payload;
    },

    clearAllDataLists: (state) =>
      void ((state.activeCountEvents = []),
      (state.currentCountLogs = []),
      (state.currentCount = {
        countEventId: 0,
        countEventName: "",
        countEventDate: new Date().getTime(),
        itemsList: [],
        active: false,
      })),
  },
});

export const {
  setActiveCountEvents,
  setCurrentCount,
  setCurrentCountLogs,
  clearAllDataLists,
} = dataSlice.actions;

export const selectData = (state: { data: { data: any } }) => state.data.data;

export default dataSlice.reducer;
