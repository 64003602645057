import { createTheme } from "@mui/material/styles";

export const SiteTheme = createTheme();

SiteTheme.typography.h3 = {
  fontSize: "1.8rem",
  fontFamily: "Oswald",
  color: "#06283d",
  marginTop: "45px",
  marginBottom: "25px",
};
