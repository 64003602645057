// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errMessege{

    color: red;
    text-align: center;
    margin-top:200px;
    font-size: 36px;

}`, "",{"version":3,"sources":["webpack://./src/pages/ErrorPage.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;;AAEnB","sourcesContent":[".errMessege{\n\n    color: red;\n    text-align: center;\n    margin-top:200px;\n    font-size: 36px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
