import { ChangeEvent, useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import styles from "./ProfileAvatar.module.css"; // Import CSS module
import EditIcon from "@mui/icons-material/Edit";
import { CHUser } from "../tsClasses/CHTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import uploadAvatar from "../functions/UploadAvatarFunc";

type UpdateAvatarFunction = (imgSrc: string) => void;
type CloseModalFunction = () => void;

interface ProfileAvatarProps {
  onChange: UpdateAvatarFunction; // Declare onChange as a required prop
  userAvatar?: string;
}
const ProfileAvatar = (props: ProfileAvatarProps) => {
  const [file, setfile] = useState<File>();
  const [av1, setAv1] = useState("/images/users/avatars/no_image.png");

  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  useEffect(() => {
    console.log("inside use effect")
    if(props.userAvatar ) {
    setAv1(props.userAvatar);
    }
  }, [props.userAvatar, userToken, ],);


  const avatarUrl = useRef(
    props.userAvatar ? props.userAvatar : "/images/users/avatars/no_image.png"
  );


  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar: UpdateAvatarFunction = (imgSrc) => {
    setAv1(imgSrc); // Assign a function that returns imgSrc
    console.log(imgSrc);
    let imageLink: string = "";
    uploadAvatar(userToken, imgSrc).then(async (res) => {
      if (res != null) {
        imageLink = res;
        console.log(imageLink);
        props.onChange(imageLink);
      } else {
        props.onChange(imageLink);
      }
    });
  };

  const closeModal: CloseModalFunction = () => {
    setModalOpen(false);
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file1 = e.target.files?.[0];
    console.log(file1);
    if (!file1) return;
    else {
      setfile(file1);
      return file1;
    }
  };

  return (
    <div className={styles.profileContainer}>
      <div className={styles.avatarContainer}>
        <img
          src={av1}
          alt="Avatar"
          className={styles.avatarImage}
        />
        <button
          className={styles.changePhotoButton}
          title="Change photo"
          onClick={() => setModalOpen(true)}
        >
          <EditIcon color="primary" />
        </button>
      </div>
      {modalOpen && (
        <Modal updateAvatar={updateAvatar} closeModal={closeModal} />
      )}
    </div>
  );
};

export default ProfileAvatar;
