import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridToolbar,
} from "@mui/x-data-grid";
import { demoData } from "../functions/DemoCountLogDTOData";
import { RootState } from "../store/store";
import { CHUser, CountEvent } from "../tsClasses/CHTypes";
import { useSelector } from "react-redux";
import { heIL } from "@mui/x-data-grid/locales";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

export default function CurrentCountLogsTable() {
  const [rowId, setRowId] = React.useState(null);

  const data = demoData;

  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const user: CHUser | null = useSelector(
    (state: RootState) => state.user.chUSER
  );
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  const initialRows: GridRowsProp = demoData.map((x) => ({
    id: x.logId,
    firstName: x.firstName,
    dateTime: new Date(x.dateTime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }),
    itemSKU: x.itemSKU,
    itemDescription: x.itemDescription,
    qty: x.qty,
    location: x.location,
    note1: x.note1,
    itemAttributes: x.itemAttributes,
  }));
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(demoData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "exportedData.xlsx");
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "מזהה",
      width: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "firstName",
      headerName: "שם סופר",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    { field: "dateTime", headerName: "תאריך/שעה", width: 180, align: "right" },
    { field: "itemSKU", headerName: 'מק"ט', width: 225, align: "right" },
    {
      field: "itemDescription",
      headerName: "תיאור פריט",
      width: 300,
      align: "right",
    },
    {
      field: "qty",
      headerName: "כמות",
      width: 65,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    { field: "location", headerName: "מיקום", width: 300, align: "right" },
    {
      field: "note1",
      headerName: "הערה",
      width: 100,
      align: "right",
      editable: true,
    },
    {
      field: "itemAttributes",
      headerName: "מאפיינים",
      width: 400,
      align: "right",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div
      style={{
        width: "fit-content",
        padding: "50px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <img
        src="./images/icons/xls.png"
        style={{
          width: "50px",
          height: "55px",
          cursor: "pointer",
          padding: "15px",
        }}
        onClick={exportToExcel}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              location: false,
              itemAttributes: false,
            },
          },
        }}
        slotProps={{
          toolbar: {
            csvOptions: { allColumns: true },
            setRows,
            setRowModesModel,
          },
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </div>
  );
}
