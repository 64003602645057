import React, { useEffect } from "react";
import {
  DataGrid,
  GridRowModel,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import { CHUser, CountEvent, CountLogDTO, Item } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import CHeader from "../button/CHeader";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import UpdateCountLogFunc from "../functions/UpdateCountLogFunc";
import getAllCurrentCountLogsDTO from "../functions/FetchAllCurrentCountLogsDTO";
import { setCurrentCountLogs } from "../store/dataSlice";
import { heIL } from "@mui/x-data-grid/locales";
import FetchAllCountEvents from "../functions/FetchAllCountEvents";
import { Box, Fab } from "@mui/material";
import moment from "moment";
import UpdateCountEventFunc from "../functions/UpdateCountEventFunc";
import CountEventsDataGridItemList from "./CountEventsDataGridItemList";
import { hover } from "@testing-library/user-event/dist/hover";
import CusatomDataGridToolBar from "../button/CusatomDataGridToolBar";
import { DataArray } from "@mui/icons-material";

interface User {
  name: string;
  age: number;
  id: GridRowId;
  dateCreated: Date;
  lastLogin: Date;
}

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  if (newRow.countEventName !== oldRow.countEventName) {
    return `שם הספירה`;
  }
  if (newRow.countEventDate !== oldRow.countEventDate) {
    return `תאריך הספירה`;
  }
  if (newRow.active !== oldRow.active) {
    if (newRow.active) {
      return ` הספירה חזרה לפעילה`;
    } else {
      return `הספירה ללא פעילה`;
    }
  }
  return null;
}

export default function CountEventsDataGrid2() {
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);
  const [allCountsEvents, setAllCountsEvents] = React.useState<CountEvent[]>(
    []
  );
  const [itemsList, setitemsList] = React.useState<Item[]>([]);
  const [countEventName, setCountEventName] = React.useState("");
  const [openItemList, setOpenItemList] = React.useState(false);
  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const user: CHUser | null = useSelector(
    (state: RootState) => state.user.chUSER
  );
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  const currentCountLogs2: CountLogDTO[] | null = useSelector(
    (state: RootState) => state.data.currentCountLogs
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("inside the useffect");
    const fetchCounts = async () => {
      try {
        const counts = await FetchAllCountEvents(userToken);
        console.log(counts.at(0)?.countEventName);
        setAllCountsEvents(counts);
      } catch (error) {
        console.error("Error fetching count events:", error);
      }
    };

    fetchCounts();
  }, [userToken]);

  const initialRows: GridRowsProp = allCountsEvents.map((x) => ({
    countEventId: x.countEventId,
    countEventName: x.countEventName,
    countEventDate: new Date(x.countEventDate),
    active: x.active,
    itemlist: x.itemsList,
  }));

  const [rows, setRows] = React.useState(initialRows);

  useEffect(() => {
    setRows(initialRows);
  }, [allCountsEvents]);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      const propsToUpdate = {
        countEventId: newRow.countEventId,
        countEventName: newRow.countEventName,
        countEventDate: newRow.countEventDate,
        active: newRow.active,
        userToken: userToken,
      };

      const response = await UpdateCountEventFunc(propsToUpdate);
      setSnackbar({
        children: "Log successfully updated",
        severity: "success",
      });

      setPromiseArguments(null);
      window.location.reload();
    } catch (error) {
      setSnackbar({ children: "Log fails to save", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const handleItemListOnClick = (params: any) => {
    if (openItemList) {
      setOpenItemList(false);
      setitemsList([]);
      setCountEventName("");
    } else {
      setOpenItemList(true);
      setCountEventName(params.countEventName);
      setitemsList(params.itemlist);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "countEventId",
      headerName: "מזהה",
      width: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countEventName",
      headerName: "שם הספירה",
      width: 350,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "countEventDate",
      headerName: "תאריך",
      width: 120,
      align: "right",
      editable: true,
      type: "date",
      renderCell: (params) =>
        moment(params.row.countEventDate).format("DD/MM/YYYY"),
    },
    {
      field: "active",
      headerName: "ספירה פעילה",
      width: 100,
      align: "center",
      type: "boolean",
      editable: true,
    },
    {
      field: "itemList",
      headerName: "רשימת פריטים",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          <Fab
            sx={{
              width: 35,
              height: 35,
              boxShadow: 0,
              backgroundColor:
                openItemList && params.row.countEventName === countEventName
                  ? "#C5FF95"
                  : "#DDDDDD",
              "&:hover": {
                backgroundColor:
                  openItemList && params.row.countEventName === countEventName
                    ? "#90D26D"
                    : "#EEEEEE",
              },
            }}
            onClick={() => handleItemListOnClick(params.row)}
          >
            <ListAltIcon />
          </Fab>
        </Box>
      ),
    },
  ];

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
        sx={{ direction: "rtl" }}
      >
        <DialogTitle>האם אתה בטוח?</DialogTitle>
        <DialogContent dividers>
          {`לחיצה על כן תשנה את  ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            לא
          </Button>
          <Button onClick={handleYes}>כן</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const cusatomDataGridToolBarProps = {
    dataArrey: allCountsEvents,
    worksheetName: "AllCountsEvents",
    fileName: "Counts Events",
  };

  function CustomToolbar() {
    return CusatomDataGridToolBar(cusatomDataGridToolBarProps);
  }

  return (
    <div
      style={{
        maxWidth: "50%",
        padding: "0px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <CHeader type={1}>ניהול ספירות</CHeader>
      {renderConfirmDialog()}
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.countEventId}
        sx={{
          boxShadow: 2,
          align: "center",
          flexGrow: 1,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              location: false,
              itemAttributes: false,
              note5: false,
            },
          },
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      <br />
      {openItemList && (
        <CountEventsDataGridItemList
          itemsList={itemsList}
          countEventName={countEventName}
        />
      )}
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
