import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectUser from "./SelectUser";
import { RootState } from "../../store/store";
import FetchAllUsersFunc from "../../functions/FetchAllUsersFunc";
import { CHUser } from "../../tsClasses/CHTypes";
import { Update } from "@mui/icons-material";
import LoadingModal from "../../modals/LoadingModal";
import UpdateUserForm from "./UpdateUserForm";

export const ManageUsers = () => {
  const init: CHUser[] = [];
  const [allusers, setallUsers] = useState(init);
  const [userToEdit, setUserToEdit] = useState<CHUser | null>(null); // Specify the type of userToEdit
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const userToken: string = useSelector(
    (state: RootState) => state.user.userToken
  );

  useEffect(() => {
    FetchAllUsersFunc(userToken).then((res) => {
      console.log(res);
      setallUsers(res);
    });
  }, [userToEdit]);

  const handleUserSelection = (selectedUser: CHUser) => {
    setUserToEdit(selectedUser);
  };

  const handleUserDeSelection = () => {
    setUserToEdit(null);
  };


  return (
    <Fragment>
       <LoadingModal open={isLoadingModalOpen}></LoadingModal>
      {userToEdit == null ? (
        <SelectUser usersList={allusers} onClick={handleUserSelection} />
      ) : (
        <UpdateUserForm userToUpdate={userToEdit} action={handleUserDeSelection}/>
      )}
    </Fragment>
  );
};
