import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import CountLogDataFGridSum from "./CountLogDataFGridSum";
import { CountEvent } from "../tsClasses/CHTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CHeader from "../button/CHeader";
import { useNavigate } from "react-router-dom";
import CountLogDataFGridExtended from "./CountLogDataFGridExtended";

export default function CountLogDataFGrid() {
  const [dispayOptions, setDispayOptions] = useState("Extended");
  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const navigate = useNavigate();

  const handleDispalyChange = (
    event: React.MouseEvent<HTMLElement>,
    newDispaly: string
  ) => {
    setDispayOptions(newDispaly);
  };

  console.log("Hi From CountLogDataFGrid");

  const checkCurrentCount = () => {
    if (currentCount.countEventId == 0) {
      navigate("/SelectCountEvent");
    }
  };

  const display = () => {
    if (currentCount.countEventId == 0) {
      return (
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <CHeader type={2}>לא נבחרה ספירה</CHeader>
        </div>
      );
    }

    if (dispayOptions === "Extended") {
      return (
        <>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={dispayOptions}
              exclusive
              onChange={handleDispalyChange}
              aria-label="dispayOptionsForm"
            >
              <ToggleButton value="Sum">תצוגת סיכום</ToggleButton>
              <ToggleButton value="Extended">תצוגת תנועות</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <CountLogDataFGridExtended />
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={dispayOptions}
              exclusive
              onChange={handleDispalyChange}
              aria-label="dispayOptionsForm"
            >
              <ToggleButton value="Sum">תצוגת סיכום</ToggleButton>
              <ToggleButton value="Extended">תצוגת תנועות</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <CountLogDataFGridSum />
        </>
      );
    }
  };

  return <>{display()}</>;
}
