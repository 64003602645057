import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import classes from "./SelectCountEvent.module.css";
import { CountEvent } from "../tsClasses/CHTypes";
import CountEventCard from "../cards/CountEventCard";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCount, setCurrentCountLogs } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import getAllCurrentCountLogsDTO from "../functions/FetchAllCurrentCountLogsDTO";

interface CountEventListProps {
  countEventsList: CountEvent[];
  onClick?: () => void;
  actionType?: string;
}

const SelectCountEvent: React.FC<CountEventListProps> = ({
  countEventsList,
  onClick,
  actionType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  const newOnClickHandle =
    (countEvent: CountEvent) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      console.log("CountEventPress" + countEvent.countEventName);
      dispatch(setCurrentCount(countEvent));
      getAllCurrentCountLogsDTO(countEvent.countEventId, userToken).then(
        (res) => {
          console.log("CurrentCountLog" + res);
          if (res != null) {
            dispatch(setCurrentCountLogs(res));
          }
          if (res == null) {
            console.log("CurrentCountLog res is null");
          }
        }
      );
      navigate("/SelectItemToCount");
    };

  console.log("Hello from SelectCountEvent");
  if (countEventsList.at(0) == null) {
    return (
      <div className={classes.no_items_div}>
        {" "}
        No count events to display here
      </div>
    );
  } else {
    return (
      <div className={classes.main_list_div}>
        <div className={classes.formHeader}>בחר ספירה</div>
        <br />
        <div className={classes.div_item}>
          {countEventsList.map((countEvent) => (
            <CountEventCard
              key={countEvent.countEventId}
              countEvent={countEvent}
              onClick={newOnClickHandle(countEvent)}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default SelectCountEvent;
