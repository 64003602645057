import { CountEvent } from "../tsClasses/CHTypes";
import classes from "./CountEventCard.module.css"
import Moment from "moment";

interface CountCardProps {
    countEvent: CountEvent;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    actionType?: string;
  }

  const CountEventCard: React.FC<CountCardProps> = ({ countEvent, onClick, actionType }) => {
    console.log("Hello from CountEventCard");


  return (
    <div className={classes.maindiv} onClick={onClick}>
    <div className={classes.count_event_name}>{countEvent.countEventName}</div>
    <div className={classes.count_date}>{Moment(countEvent.countEventDate).format("DD/MM/YYYY")}</div>
    
   
    </div>
  );

}

export default CountEventCard;
