import { PixelCrop } from "react-image-crop";

export const apiPath: string = "https://www.chelper.net/ChelperWebApi/api";

export function capitalizeFirstLetter(str: string): string {
  if (!str) return ""; // Handle empty string or null/undefined input
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getCurrentDateTime(): string {
  const now: Date = new Date();
  const year: number = now.getFullYear();
  const month: number = now.getMonth() + 1; // Months are 0 indexed, so we add 1
  const day: number = now.getDate();
  const hours: number = now.getHours();
  const minutes: number = now.getMinutes();
  const seconds: number = now.getSeconds();

  // Ensure two-digit format for month, day, hour, minute, and second
  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
  const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
  const formattedHours: string = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds: string = seconds < 10 ? `0${seconds}` : `${seconds}`;

  // Construct the date and time string in desired format
  const dateTimeString: string = `${year}-${formattedMonth}-${formattedDay}T${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return dateTimeString;
}

// const skus: string[] = extractSKUs(pasteSKU);

export function extractSKUs(inputString: string): string[] {
  // Split the input string by spaces, commas, or semicolons to get individual SKUs
  const skus: string[] = inputString.split(/\s+|,|;/);

  // Filter out any empty strings from the split operation
  const filteredSKUs: string[] = skus.filter((sku) => sku.trim() !== "");

  return filteredSKUs;
}

export function printSKUs(skus: string[]): void {
  console.log("Hi Im printSKUs =========");
  skus.forEach((sku) => {
    console.log(sku);
  });
}





