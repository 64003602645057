import { apiPath } from "./GeneralFunc";

const LoginFunc = async (props: { email: string; password: string }) => {
  const dataToSend = {
    email: props.email,
    password: props.password,
  };

  console.log(dataToSend);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataToSend),
  };

  console.log(requestOptions);

  try {
    const response = await fetch(apiPath + "/login", requestOptions);
    if (response == null || !response.ok) {
      console.log("Something went wrong!");
      return "Error";
    }
    const UserFromDB = await response.text();
    console.log("idFromDB: " + UserFromDB);
    return UserFromDB;
  } catch (error) {
    return "Error";
  }
};

export default LoginFunc;
