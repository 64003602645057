// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Profile.module.css */

.ProfileAvatar_profileContainer__1WKVV {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
  }
  
  .ProfileAvatar_avatarContainer__bF1b0 {
    position: relative;
  }
  
  .ProfileAvatar_avatarImage__5F8XQ {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid #ccc;
  }
  
  .ProfileAvatar_changePhotoButton__02lxz {
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    right: 0;
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.35rem;
    border-radius: 50%;
    background-color: #0c2d57;
    border: 1px solid #666;
  }
  
  .ProfileAvatar_changePhotoButton__02lxz:hover {
    background-color: #444;
  }
  
  .ProfileAvatar_profileInfo__89dFP {
    color: #fff;
    font-weight: bold;
    margin-top: 1.5rem;
  }
  
  .ProfileAvatar_profileTitle__elHKF {
    color: #999;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/button/ProfileAvatar.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":["/* Profile.module.css */\n\n.profileContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 3rem;\n  }\n  \n  .avatarContainer {\n    position: relative;\n  }\n  \n  .avatarImage {\n    width: 150px;\n    height: 150px;\n    border-radius: 50%;\n    border: 2px solid #ccc;\n  }\n  \n  .changePhotoButton {\n    position: absolute;\n    bottom: -0.75rem;\n    left: 0;\n    right: 0;\n    margin: auto;\n    width: fit-content;\n    padding: 0.35rem;\n    border-radius: 50%;\n    background-color: #0c2d57;\n    border: 1px solid #666;\n  }\n  \n  .changePhotoButton:hover {\n    background-color: #444;\n  }\n  \n  .profileInfo {\n    color: #fff;\n    font-weight: bold;\n    margin-top: 1.5rem;\n  }\n  \n  .profileTitle {\n    color: #999;\n    font-size: 0.75rem;\n    margin-top: 0.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileContainer": `ProfileAvatar_profileContainer__1WKVV`,
	"avatarContainer": `ProfileAvatar_avatarContainer__bF1b0`,
	"avatarImage": `ProfileAvatar_avatarImage__5F8XQ`,
	"changePhotoButton": `ProfileAvatar_changePhotoButton__02lxz`,
	"profileInfo": `ProfileAvatar_profileInfo__89dFP`,
	"profileTitle": `ProfileAvatar_profileTitle__elHKF`
};
export default ___CSS_LOADER_EXPORT___;
