// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonCo_button__chSNA {
  font: inherit;
  border: none;
  border-radius: 8px;
  background: #0c2d57;
  color: white;
  padding: 0.25rem 1rem;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  padding: 6px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ButtonCo_button__chSNA:hover,
.ButtonCo_button__chSNA:active {
  background-color: #1e5eb1;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.ButtonCo_button__chSNA:disabled {
  color: #7a7a7a;
  background-color: #454647;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  cursor: default;
}

.ButtonCo_button__chSNA:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/modals/ButtonCo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf;uEACqE;EACrE,uDAAuD;EACvD,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB;;;gDAG8C;AAChD;;AAEA;;EAEE,yBAAyB;EACzB;uEACqE;AACvE;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB;uEACqE;EACrE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".button {\n  font: inherit;\n  border: none;\n  border-radius: 8px;\n  background: #0c2d57;\n  color: white;\n  padding: 0.25rem 1rem;\n  cursor: pointer;\n  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),\n    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n  font-weight: 600;\n  font-size: 0.875rem;\n  line-height: 1.75;\n  letter-spacing: 0.02857em;\n  min-width: 64px;\n  padding: 6px 16px;\n  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n.button:hover,\n.button:active {\n  background-color: #1e5eb1;\n  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),\n    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);\n}\n\n.button:disabled {\n  color: #7a7a7a;\n  background-color: #454647;\n  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),\n    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);\n  cursor: default;\n}\n\n.button:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonCo_button__chSNA`
};
export default ___CSS_LOADER_EXPORT___;
