import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import LoadingModal from '../modals/LoadingModal';
import BasicModal from '../modals/BasicModal';
import { FormControl, Grid, IconButton, TextField } from '@mui/material';
import classes from "./Form.module.css";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ButtonCo from '../modals/ButtonCo';
import ChangePasswordFunc from '../functions/ChangePasswordFunc';
import CHeader from '../button/CHeader';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

export default function ChangePasswordForm() {
    const navigate = useNavigate();
    const chUserFromRedux = useSelector((state: RootState) => {
        if (state.user.chUSER) {
          return state.user.chUSER;
        }
        return null;
      });
      const [isOldPasswordValid, setisOldPasswordValid] = useState(false);
      const [isNewPasswordValid, setisNewPasswordValid] = useState(false);
      const [isNewPasswordRepiteValid, setisNewPasswordReapetValid] = useState(false);
      const [isOldPasswordBlur, setisOldPasswordBlur] = useState(false);
      const [isNewPasswordBlur, setisNewPasswordBlur] = useState(false);
      const [isNewPasswordRepiteBlur, setisNewPasswordReapetBlur] = useState(false);
      const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
      const [errorTitle, setErrorTitle] = useState("");
      const [errorMessage, setErrorMessage] = useState("");
      const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
      const [showPassword, setshowPassword] = useState(false);
      const [showOldPassword, setshowOldPassword] = useState(false);
      const [showNewPassword, setshowNewPassword] = useState(false);
      const [showNewPasswordRepite, setshowNewPasswordRepite] = useState(false);
      const valid =  isNewPasswordValid && isOldPasswordValid && isNewPasswordRepiteValid
      const userToken: string | null = useSelector(
        (state: RootState) => state.user.userToken
      );
      
      const [inputs, setInputs] = useState({
        userId: chUserFromRedux?.userId,
        oldPassword: "",
        newPassword: "",
        newPasswordRepite: "",
      });

      const handleOldPasswordChange = (event: { target: { value: string | any[]; name: any; }; }) => {
        if (event.target.value.length > 4) {
            setisOldPasswordValid(true);
        }else {
            setisOldPasswordValid(false);
        }
        setInputs((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.value,
        }));
        console.log("setedPassChange");
      };
    

      const handleNewPasswordChange = (event: { target: { value: string | any[]; name: any; }; }) => {
        if (event.target.value.length > 5) {
            console.log("inside first If " +event.target.value );
            setisNewPasswordValid(true);
            if (event.target.value === inputs.newPasswordRepite) {
                setisNewPasswordReapetValid(true)
            }
        } else {
            setisNewPasswordValid(false);
            setisNewPasswordReapetValid(false)
        }
    
        setInputs((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
          }));
      };

      const handleNewPasswordRepiteChange = (event: { target: { value: string | any[]; name: any; }; }) => {
        if (event.target.value == inputs.newPassword) {
            console.log("inside true" + event.target.value)
            console.log("old pass status" + isOldPasswordValid)
            console.log("new pass status" + isNewPasswordValid)
            console.log("new pass repeti status" + isNewPasswordRepiteValid)
                setisNewPasswordReapetValid(true)

                
            
        } else {
            console.log("inside false" + event.target.value)
            setisNewPasswordReapetValid(false)
            
        }
    console.log("new pass repeti status" + isNewPasswordRepiteValid)
        setInputs((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.value,
        }));
      };

      const handleBackOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate("/UpdateProfile")
       }
    


      const newOnClickHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    
       const dataToSend  = {
        userId: inputs.userId!,
        oldPassword : inputs.oldPassword,
        newPassword : inputs.newPassword,
        userToken: userToken,
       }
    
        if (valid) {
          setisLoadingModalOpen(true);
          ChangePasswordFunc(dataToSend).then((res) => {
            setisLoadingModalOpen(false);
            if (res == "true" ) {
                setInputs({
                    userId: chUserFromRedux?.userId,
                    oldPassword: "",
                    newPassword: "",
                    newPasswordRepite: "",
                  })

                  navigate("/UpdateProfile")
            }
    
            else if (res == "false") {
              setErrorTitle("Incorrect password");
              setErrorMessage(
                "Please check your old password and try again"
              );
              setisErrorModalOpen(true);
            }
    
            else  {
              console.log("on error model");
              setErrorTitle("Server issues");
              setErrorMessage("Please try again in few minutes");
              setisErrorModalOpen(true);
              console.log(isErrorModalOpen);
            }
          });
        }
      };
    


    
      


  return (
    <div className={classes.main_form_div}>
    <LoadingModal open={isLoadingModalOpen}></LoadingModal>
    <BasicModal
      open={isErrorModalOpen}
      onClose={() => setisErrorModalOpen(false)}
      onConfirm={() => setisErrorModalOpen(false)}
      title={errorTitle}
      message={errorMessage}
    />
     <CHeader type={2} >החלפת סיסמה</CHeader>
     <br />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
     
       <TextField
        value={inputs.oldPassword}
        name="oldPassword"
        onBlur={() => setisOldPasswordBlur(true)}
        onChange={handleOldPasswordChange}
        type={showOldPassword ? "text" : "password"}
        label="סיסמה נוכחית"
        helperText="(נדרש)"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onMouseDown={() => setshowOldPassword(true)}
              onMouseUp={() => setshowOldPassword(false)}
              onMouseLeave={() => setshowOldPassword(false)}
              edge="end"
            >
              {showOldPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
         </FormControl>
              </Grid>
      <Grid item xs={12}>
                <FormControl fullWidth>
       <TextField
        value={inputs.newPassword}
        name="newPassword"
        onBlur={() => setisNewPasswordBlur(true)}
        onChange={handleNewPasswordChange}
        type={showNewPassword ? "text" : "password"}
        label="סיסמה חדשה"
        helperText="(נדרש - לכל הפחות 6 תווים)"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onMouseDown={() => setshowNewPassword(true)}
              onMouseUp={() => setshowNewPassword(false)}
              onMouseLeave={() => setshowNewPassword(false)}
              edge="end"
            >
              {showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
         </FormControl>
              </Grid>
      <Grid item xs={12}>
                <FormControl fullWidth>
<TextField
        value={inputs.newPasswordRepite}
        name="newPasswordRepite"
        onBlur={() => setisNewPasswordReapetBlur(true)}
        onChange={handleNewPasswordRepiteChange}
        type={showNewPasswordRepite ? "text" : "password"}
        label="חזור על סיסמה חדשה"
        helperText="(נדרש)"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onMouseDown={() => setshowNewPasswordRepite(true)}
              onMouseUp={() => setshowNewPasswordRepite(false)}
              onMouseLeave={() => setshowNewPasswordRepite(false)}
              edge="end"
            >
              {showNewPasswordRepite ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
       </FormControl>
              </Grid>
      <br />
      
      <br />
      <Grid item xs={12}>
                <FormControl fullWidth>
      <ButtonCo type="submit" onClick={newOnClickHandle} disabled={!valid}>
        החלף סיסמה
      </ButtonCo>

      </FormControl>
              </Grid>

              <Grid item xs={12}>

      <IconButton onClick={handleBackOnClick}>
      <ArrowBackIosOutlinedIcon  color="primary" />

      </IconButton>

              </Grid>
      </Grid>
  </div>
  )
}
