import {
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonCo from "../modals/ButtonCo";
import { CHUser, UserType, getUserTypeFromString } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import LoadingModal from "../modals/LoadingModal";
import SuccessModal from "../modals/SuccessModal";
import BasicModal from "../modals/BasicModal";
import CHeader from "../button/CHeader";
import classes from "../countForms/CountForms.module.css";
import ProfileAvatar from "../button/ProfileAvatar";
import UpdateUserFunc from "../functions/UpdateUserFunc";
import { useDispatch } from "react-redux";
import { setAvatar, updateLoggedUser } from "../store/userSlice";
import FetchAvatar from "../functions/FetchAvatar";

export default function UpdateUserProfile() {
  console.log("Hello from AddUserForm");

  const [isEmailValid, setisEmailValid] = useState(true);
  const [isFirstNameValid, setisFirstNameValid] = useState(true);
  const [isLastNameValid, setisLastNameValid] = useState(true);
  const [isMobilePhoneValid, setisMobilePhoneValid] = useState(true);
  const [isEmailBlur, setisEmailBlur] = useState(false);
  const [isFirstNameBlur, setisFirstNameBlur] = useState(false);
  const [isLastNameBlur, setisLastNameBlur] = useState(false);
  const [isMobilePhoneBlur, setisMobilePhoneBlur] = useState(false);
  const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const [isSuccessModallOpen, setisSuccessModallOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allInputsValid = isEmailValid && isFirstNameValid && isLastNameValid;

  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  const UserFromRedux = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER;
    }
    return null;
  });

  const AvatarFromRedux = useSelector((state: RootState) => {
    if (state.user.avatarURL) {
      return state.user.avatarURL;
    }
    return null;
  });

  const handleEmailChange = (e: { target: { value: string; name: any } }) => {
    if (isEmail(e.target.value)) {
      setisEmailValid(true);
    } else {
      setisEmailValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(isEmailValid);
  };

  const handleFirstNameChange = (ev: {
    target: { value: string | any[]; name: any };
  }) => {
    if (ev.target.value.length >= 2) {
      setisFirstNameValid(true);
    } else {
      setisFirstNameValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
    console.log(isFirstNameValid);
  };

  const handleLastNameChange = (ev: {
    target: { value: string | any[]; name: any };
  }) => {
    if (ev.target.value.length >= 2) {
      console.log("its ok");
      setisLastNameValid(true);
    } else {
      console.log("not ok");
      setisLastNameValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
    console.log(isLastNameValid);
  };

  const rgxZIP = /^[0-9]*$/;

  const handleMobilePhoneChange = (ev: {
    target: { value: string; name: any };
  }) => {
    if (ev.target.value.length >= 10) {
      setisMobilePhoneValid(true);
    } else {
      setisMobilePhoneValid(false);
    }

    if (rgxZIP.test(ev.target.value) && ev.target.value.length < 11) {
      setInputs((prevState) => ({
        ...prevState,
        [ev.target.name]: ev.target.value,
      }));
    }
  };

  const handleAvatarChange = (imageLink: string) => {
    setInputs((prevState) => ({
      ...prevState,
      avatar: imageLink,
    }));

    console.log(imageLink);
  };

  const handleChangePasswordClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate("/ChangePasswordForm")
  };

  const [inputs, setInputs] = useState({
    userId: UserFromRedux!.userId,
    firstName: UserFromRedux!.firstName,
    lastName: UserFromRedux!.lastName,
    userType: UserFromRedux!.userType,
    email: UserFromRedux!.email,
    mobilePhone: UserFromRedux!.mobilePhone,
    password: "",
    avatar: UserFromRedux!.avatar,
    active: UserFromRedux!.active,
  });

  function delay(ms: number | undefined) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handelOnSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (allInputsValid) {
      setisLoadingModalOpen(true);
      console.log(inputs.avatar);
      const userToUpdate: CHUser = {
        userId: UserFromRedux!.userId,
        password: inputs.password,
        email: inputs.email,
        userType: UserFromRedux!.userType,
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        mobilePhone: inputs.mobilePhone,
        avatar: inputs.avatar,
        active: true,
      };

      let propsToSend = {
        userToUpdate: userToUpdate,
        userToken: userToken!,
      };

      UpdateUserFunc(propsToSend).then(async (res) => {
        setisLoadingModalOpen(false);
        if (!res.includes("Error") || res == "gotErr") {
          setisSuccessModallOpen(true);
          dispatch(updateLoggedUser(JSON.parse(res)))
          FetchAvatar(userToUpdate.avatar,userToken ).then(async (res) => {
            if (res!=null) {
                const imageUrl = URL.createObjectURL(res);
                dispatch(setAvatar(imageUrl))
              }
            })
          await delay(1500);
          setisSuccessModallOpen(false);
          setInputs({
            userId: 0,
            firstName: "",
            lastName: "",
            userType: UserType.COUNTING,
            email: "",
            mobilePhone: "",
            password: "",
            avatar: "",
            active: false,
          });

          navigate("/");
        }

        if (res.includes("Error")) {
          console.log("AddUserForm=>handelOnSubmit=> res=Error");
          let error = JSON.parse(res);
          let errorMSG = error.value;
          setErrorTitle("Error");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "Unauthorized") {
          console.log("AddUserForm=>handelOnSubmit=> res=Error");
          let errorMSG = "Unauthorized Action";
          setErrorTitle("Unauthorized");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "gotErr") {
          setErrorTitle("Server issues");
          setErrorMessage("Please try again in few minutes");
          setisErrorModalOpen(true);
          console.log(res);
        }
      });
    }
  };

  return (
    <div className={classes.main_form_div}>
      <LoadingModal open={isLoadingModalOpen}></LoadingModal>
      <SuccessModal open={isSuccessModallOpen} />
      <BasicModal
        open={isErrorModalOpen}
        onClose={() => setisErrorModalOpen(false)}
        onConfirm={() => setisErrorModalOpen(false)}
        title={errorTitle}
        message={errorMessage}
      />
      <CHeader type={2}>עדכן פרופיל</CHeader>
      <ProfileAvatar
        onChange={handleAvatarChange}
        userAvatar={AvatarFromRedux!}
      />
      <br></br>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              value={inputs.firstName}
              error={isFirstNameBlur && isFirstNameValid === false}
              onBlur={() => setisFirstNameBlur(true)}
              onChange={handleFirstNameChange}
              name="firstName"
              type="text"
              helperText="(נדרש)"
              label="שם פרטי"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              value={inputs.lastName}
              error={isLastNameBlur && isLastNameValid === false}
              onBlur={() => setisLastNameBlur(true)}
              name="lastName"
              onChange={handleLastNameChange}
              type="text"
              helperText="(נדרש)"
              label="שם משפחה"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              error={isEmailBlur && isEmailValid === false}
              onBlur={() => setisEmailBlur(true)}
              value={inputs.email}
              name="email"
              placeholder=""
              helperText="(נדרש)"
              onChange={handleEmailChange}
              label="כתובת דואל"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              value={inputs.userType}
              name="userType"
              label="תפקיד"
              variant="outlined"
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              value={inputs.mobilePhone}
              error={isMobilePhoneBlur && isMobilePhoneValid === false}
              onBlur={() => setisMobilePhoneBlur(true)}
              name="mobilePhone"
              onChange={handleMobilePhoneChange}
              type="text"
              helperText="(נדרש)"
              label="טלפון נייד"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ButtonCo
            type={"submit"}
            onClick={handelOnSubmit}
            disabled={!allInputsValid}
          >
            עדכן פרופיל
          </ButtonCo>
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth>
            <ButtonCo  type={"submit"}
            onClick={handleChangePasswordClick}>החלפת סיסמא</ButtonCo>
          </FormControl>
      
        </Grid>
      </Grid>
    </div>
  );
}
