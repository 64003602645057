// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectCountEvent_main_list_div__nOBAg {

    margin-top: 4vh;
}

.SelectCountEvent_div_item__qZXOd {
    margin-top: 25px;
}


.SelectCountEvent_no_items_div__\\+-Fy9 {

    margin-top: 4vh;
    
}
.SelectCountEvent_formHeader__H7m6T {
    color: #0c2d57;
    text-align: center;
    font-size: 32px;
    font-weight: 600; 
    margin-top: 3vh;

  }

@media (min-width: 768px) {
    .SelectCountEvent_modal__qUtLL {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/SelectCountEvent.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;;IAEI,eAAe;;AAEnB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;;EAEjB;;AAEF;IACI;MACE,uBAAuB;MACvB,YAAY;IACd;EACF","sourcesContent":[".main_list_div {\n\n    margin-top: 4vh;\n}\n\n.div_item {\n    margin-top: 25px;\n}\n\n\n.no_items_div {\n\n    margin-top: 4vh;\n    \n}\n.formHeader {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 32px;\n    font-weight: 600; \n    margin-top: 3vh;\n\n  }\n\n@media (min-width: 768px) {\n    .modal {\n      left: calc(50% - 20rem);\n      width: 40rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_list_div": `SelectCountEvent_main_list_div__nOBAg`,
	"div_item": `SelectCountEvent_div_item__qZXOd`,
	"no_items_div": `SelectCountEvent_no_items_div__+-Fy9`,
	"formHeader": `SelectCountEvent_formHeader__H7m6T`,
	"modal": `SelectCountEvent_modal__qUtLL`
};
export default ___CSS_LOADER_EXPORT___;
