// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectMainProductGroup_main_list_div__Q9\\+Jy {

    margin-top: 4vh;
}

.SelectMainProductGroup_div_item__NZ8vu {
    margin: 25px;
    padding: auto;
}


.SelectMainProductGroup_no_items_div__csgW3 {

    margin-top: 4vh;
    
}

.SelectMainProductGroup_formHeader__aPRTT {
    color: #0c2d57;
    text-align: center;
    font-size: 32px;
    font-weight: 600; 
    margin-top: 3vh;

  }`, "",{"version":3,"sources":["webpack://./src/pages/SelectMainProductGroup.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;;AAGA;;IAEI,eAAe;;AAEnB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;;EAEjB","sourcesContent":[".main_list_div {\n\n    margin-top: 4vh;\n}\n\n.div_item {\n    margin: 25px;\n    padding: auto;\n}\n\n\n.no_items_div {\n\n    margin-top: 4vh;\n    \n}\n\n.formHeader {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 32px;\n    font-weight: 600; \n    margin-top: 3vh;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_list_div": `SelectMainProductGroup_main_list_div__Q9+Jy`,
	"div_item": `SelectMainProductGroup_div_item__NZ8vu`,
	"no_items_div": `SelectMainProductGroup_no_items_div__csgW3`,
	"formHeader": `SelectMainProductGroup_formHeader__aPRTT`
};
export default ___CSS_LOADER_EXPORT___;
