import React, { PropsWithChildren } from "react";
import classes from "./CHeader.module.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarProps, ToolbarPropsOverrides } from "@mui/x-data-grid";

export type CusatomDataGridToolBarProps = {
  dataArrey:any,
  worksheetName: string,
  fileName: string,



};

const CusatomDataGridToolBar = (props: CusatomDataGridToolBarProps) => {

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(props.dataArrey);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, props.worksheetName);
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
    
        saveAs(blob, props.fileName+".xlsx");
      };

        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <img
            src="./images/icons/xls.png"
            style={{
              height:"30px",
              cursor: "pointer",
            }}
            onClick={exportToExcel}
          />
          </GridToolbarContainer>
        );
    }

export default CusatomDataGridToolBar;
