// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BasicModal_backdrop__uiP\\+Y {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.BasicModal_modal__8TXHT {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
  
}

.BasicModal_header__kvTSU {
  background: #0c2d57;
  padding: 1rem;
  color: azure;
  text-align: center;
}

.BasicModal_header__kvTSU h2 {
  margin: 0;
  color: white;
  text-align: center;
}

.BasicModal_content__C2heT {
  padding: 1rem;
  text-align: center;
  color: #0c2d57;
  font-weight: 500;
  background-color: #F5F5FA;
  font-size: 24px;
}

.BasicModal_actions__xXX25 {
  padding: 1rem;
  display: flex;
  justify-content: center;
  background-color: #F5F5FA
}

@media (min-width: 768px) {
  .BasicModal_modal__8TXHT {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/BasicModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;;AAElB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.modal {\n  position: fixed;\n  top: 30vh;\n  left: 10%;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n  \n}\n\n.header {\n  background: #0c2d57;\n  padding: 1rem;\n  color: azure;\n  text-align: center;\n}\n\n.header h2 {\n  margin: 0;\n  color: white;\n  text-align: center;\n}\n\n.content {\n  padding: 1rem;\n  text-align: center;\n  color: #0c2d57;\n  font-weight: 500;\n  background-color: #F5F5FA;\n  font-size: 24px;\n}\n\n.actions {\n  padding: 1rem;\n  display: flex;\n  justify-content: center;\n  background-color: #F5F5FA\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `BasicModal_backdrop__uiP+Y`,
	"modal": `BasicModal_modal__8TXHT`,
	"header": `BasicModal_header__kvTSU`,
	"content": `BasicModal_content__C2heT`,
	"actions": `BasicModal_actions__xXX25`
};
export default ___CSS_LOADER_EXPORT___;
