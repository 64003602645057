import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CHUser } from "../tsClasses/CHTypes";

type InitialState = {
  chUSER: CHUser | null;
  userToken: string;
  isLogon: boolean;
  avatarURL : string;
};

const initialState: InitialState = {
  chUSER: null,
  userToken: "",
  isLogon: false,
  avatarURL: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.chUSER = action.payload.chuser;
      state.isLogon = true;
      state.userToken = action.payload.tokenString;
    },
    logout: (state) =>  {
      state.chUSER = null;
      state.isLogon = false;
      state.userToken = "";
      state.avatarURL = "";
  },
  updateLoggedUser: (state, action) => {
    state.chUSER = action.payload;

  },
  setAvatar: (state, action) => {
    state.avatarURL = action.payload;
  },
}});

export const { login, logout ,  setAvatar, updateLoggedUser} = userSlice.actions;

export const selectUser = (state: { user: { user: any } }) => state.user.user;

export default userSlice.reducer;
