import './ErrorPage.css'

function ErrorPage() {
  console.log("Hello from ErrorPage");
  return (
    <div className='errMessege'>Sorry...Page Not Found</div>
  )
}

export default ErrorPage
