import { apiPath } from "./GeneralFunc";


const FetchAvatar = async (fileName:string,userToken: string) => {
    console.log("FetchAvatar user token:" + userToken);
    console.log("FetchAvatar fiel Nam:" + fileName);
    
    const requestOptions = {
      method: "GET",
      headers: {token: userToken },
    };
  
  
    try {
      const response = await fetch(
        apiPath + "/avatars/"+fileName,
        requestOptions
      )
        if (!response.ok) {
            console.log("FetchAvatar - Something went wrong!");
            throw new Error("FetchAvatar: Failed to fetch avatar ");
          }
      return response.blob(); // Assuming avatar is returned as binary data
  // Process the avatar blob
  // For example, you can display it on the page:
  // const imageUrl = URL.createObjectURL(blob);
  // const imageElement = document.createElement('img');
  // imageElement.src = imageUrl;
  // document.body.appendChild(imageElement);
        }

    catch (error: any) {
      console.error('Error fetching avatar:', error);
    }
     

};
  
  export default FetchAvatar;
  