// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountForm_main_div__uCNf6 {

    margin-top: 4vh;
    text-align: center;
    font-size: xx-large;
    color: #0c2d57;
    font-weight: 900;

}

.CountForm_formHeader__uB1fq {
    color: #0c2d57;
    text-align: center;
    font-size: 28px;
    font-weight: 600; 
    margin: 4vh;
  }

  .CountForm_main_form_div__B\\+je8 {

    max-width: 300px;
    margin: auto;
  }

  .CountForm_sku_div__Yrh\\+r{
    line-height: 2;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid grey;
    background: #FFF2E1;
    color: rgb(70, 67, 119);
    font-size: 14px;
    font-weight: 500;
    margin: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/CountForm.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;;EAErB","sourcesContent":[".main_div {\n\n    margin-top: 4vh;\n    text-align: center;\n    font-size: xx-large;\n    color: #0c2d57;\n    font-weight: 900;\n\n}\n\n.formHeader {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 28px;\n    font-weight: 600; \n    margin: 4vh;\n  }\n\n  .main_form_div {\n\n    max-width: 300px;\n    margin: auto;\n  }\n\n  .sku_div{\n    line-height: 2;\n    width: 100%;\n    height: 50px;\n    border-radius: 4px;\n    border: 1px solid grey;\n    background: #FFF2E1;\n    color: rgb(70, 67, 119);\n    font-size: 14px;\n    font-weight: 500;\n    margin: 15px;\n    padding-top: 5px;\n    padding-bottom: 5px;\n\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_div": `CountForm_main_div__uCNf6`,
	"formHeader": `CountForm_formHeader__uB1fq`,
	"main_form_div": `CountForm_main_form_div__B+je8`,
	"sku_div": `CountForm_sku_div__Yrh+r`
};
export default ___CSS_LOADER_EXPORT___;
