import React from "react";
import classes from "./HomePage.module.css";
import AdminNavbar from "../navbar/AdminNavbar";

export default function HomePage() {
  console.log("Hello from HomePage");

  return (
    <>
      <img
        className={classes.cover_image}
        src="/images/site_images/Picture3.png"
      />
    </>
  );
}
