// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.module.css */

.Modal_modalBackdrop__CrcQb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }
  
  .Modal_modalContent__qPMJC {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 80%;
    min-height: 60vh;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #0c2d57;
    color: #d8dee9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
  }
  
  .Modal_modalCloseButton__iC5tv {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    color: #cbd5e0;
  }
  
  .Modal_modalCloseButton__iC5tv:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  `, "",{"version":3,"sources":["webpack://./src/button/Modal.module.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,WAAW;EACb;;EAEA;IACE,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,uEAAuE;IACvE,gCAAgC;EAClC;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["/* Modal.module.css */\n\n.modalBackdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.75);\n    z-index: 10;\n  }\n  \n  .modalContent {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 95%;\n    max-width: 80%;\n    min-height: 60vh;\n    padding: 1rem;\n    border-radius: 1rem;\n    background-color: #0c2d57;\n    color: #d8dee9;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);\n    transition: all 0.3s ease-in-out;\n  }\n  \n  .modalCloseButton {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    height: 2rem;\n    width: 2rem;\n    border-radius: 1rem;\n    color: #cbd5e0;\n  }\n  \n  .modalCloseButton:hover {\n    background-color: rgba(0, 0, 0, 0.7);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackdrop": `Modal_modalBackdrop__CrcQb`,
	"modalContent": `Modal_modalContent__qPMJC`,
	"modalCloseButton": `Modal_modalCloseButton__iC5tv`
};
export default ___CSS_LOADER_EXPORT___;
