import { CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

type AddCountLogFuncProps = {
    countLogDTO : CountLogDTO,
    userToken: string,
}

const AddCountLogFunc = async (props : AddCountLogFuncProps) => {
    console.log(props);
    console.log("AddCountLogFunc: token" + props.userToken);
  
    const dataToSend = props.countLogDTO;
  
    console.log(dataToSend);
  
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: props.userToken },
      body: JSON.stringify(dataToSend),
    };
    try {
      const response = await fetch(
        apiPath+"/addCountLogDTO",
        requestOptions
      );
      if (response.status == 401) {
        console.log("AddCountLogFunc: Something went wrong!");
        return "Unauthorized";
      }
      if (response == null) {
        console.log("AddCountLogFunc: Something went wrong!");
      }
  
      const result = await response.text();
      console.log("AddCountLogFunc RESULT: " + result);
      return result;
    } catch (error) {
      return "gotErr";
    }
  };
  
  export default AddCountLogFunc;
  