import { Avatar } from "@mui/material";
import FetchAvatar from "../functions/FetchAvatar";
import { CHUser } from "../tsClasses/CHTypes";
import classes from "./UserCard.module.css";
import { useEffect, useState } from "react";

interface UserCardProps {
  user: CHUser;
  userToken: string;
  onClick?: (user: CHUser) => void;
  actionType?: string;
}

const UserCard = (props: UserCardProps) => {
  const [avatarURL, setAvatarURL] = useState("");
  console.log("Hello from UserCard");

  useEffect(() => {
    if (props.user.avatar!="") {
    getAvatarURL();
    }
  }, [props.user]);

  const getAvatarURL = () => {
    FetchAvatar(props.user.avatar, props.userToken).then(async (res) => {
      if (res != null) {
        const imageUrl = URL.createObjectURL(res);
        setAvatarURL(imageUrl);
      }
    });
  };

  const handleUserClick = () => {
    if (props.onClick) {
      props.onClick(props.user); // Pass user data to the onClick handler
    }
  };

  return (
    <div className={classes.maindiv} onClick={handleUserClick}>
      <Avatar
        alt="User"
        src={avatarURL}
        sx={{ width: 100, height: 100, margin: "auto" }}
      />
      <br />
      <div className={classes.user_name}>
        {props.user.firstName + "  " + props.user.lastName}
      </div>
      <div className={classes.user_type}>{props.user.userType}</div>
    </div>
  );
};

export default UserCard;
