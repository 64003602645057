import React from "react";
import ReactDOM from "react-dom";
import classes from "./SuccessModal.module.css";

interface SuccessModalProps {
  open: boolean;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ open }) => {
  if (!open) {
    return null;
  }

  const portalElement = document.getElementById("portal");
  if (!portalElement) {
    return null; // Return null if portal element is not found
  }

  return ReactDOM.createPortal(
    <div>
      <div className={classes.modal}>
        <div className={classes.container}>
          <img
            src="/images/check.png"
            alt="Success"
            className={classes.img45}
          />
        </div>
      </div>
    </div>,
    portalElement
  );
};

export default SuccessModal;
