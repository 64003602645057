import classes from "./SelectMainProductGroup.module.css";
import { CountEvent, Item } from "../tsClasses/CHTypes";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCount } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import MainProductGroupCard from "../cards/MainProductGroupCard";

const SelectMainProductGroup = () => {
  const navigate = useNavigate();

  const newOnClickHandle =
    (mainProductGroup: string) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      console.log("SelectMainCountGroup: " + mainProductGroup); // Log the value of mainCountGroup

      // Compare enum values directly
      switch (mainProductGroup) {
        case "הלבשה":
          console.log("Navigating to CoversCountForm");
          navigate("/CoversCountForm");
          break;
        case "מאריך":
          console.log("Navigating to ExtendersCountForm");
          navigate("/ExtendersCountForm");
          break;
        case "רגל":
          console.log("Navigating to FrameProfileCountForm");
          navigate("/FrameProfileCountForm");
          break;
        case "זוג רגליים":
          console.log("Navigating to FrameSetCountForm");
          navigate("/FrameSetCountForm");
          break;
        default:
          console.log("Navigating to OtherProductsCountForm");
          navigate("/OtherProductsCountForm");
          break;
      }
    };

  const currentCount = useSelector((state: RootState) => {
    if (state.data.currentCount) {
      return state.data.currentCount;
    }
    return null;
  });

  function getDistinctMainProductGroups(countEvent: CountEvent): string[] {
    // Extracting itemsList from the countEvent
    const itemsList: Item[] = countEvent.itemsList;

    // Creating a Set to store distinct mainCountGroup values
    const distinctMainProductGroupsSet: Set<string> = new Set();

    // Iterating over itemsList and adding mainCountGroup values to the Set
    itemsList.forEach((item: Item) => {
      distinctMainProductGroupsSet.add(item.mainProductGroup);
    });

    // Converting Set back to an array to maintain distinct values
    const distinctMainProductGroupsArray: string[] = Array.from(
      distinctMainProductGroupsSet
    );

    return distinctMainProductGroupsArray;
  }

  console.log("Hello SelectMainCountGroup");
  if (
    currentCount == null ||
    getDistinctMainProductGroups(currentCount).at(0) == null
  ) {
    return (
      <div className={classes.no_items_div}> No items to display here</div>
    );
  } else {
    return (
      <div className={classes.main_list_div}>
        <div className={classes.formHeader}>בחר קבוצת פריט</div>
        <br />
        <div className={classes.div_item}>
          {getDistinctMainProductGroups(currentCount).map(
            (mainProductGroup) => (
              <MainProductGroupCard
                key={mainProductGroup}
                mainProductGroup={mainProductGroup}
                onClick={newOnClickHandle(mainProductGroup)}
              />
            )
          )}
        </div>
      </div>
    );
  }
};

export default SelectMainProductGroup;
