export const demoData = [
    {
        "logId": 1,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 2,
        "firstName": "Israel",
        "lastName": "Israeli",
        "dateTime": "2024-03-30T10:15:00",
        "itemId": 3358,
        "itemSKU": "4MELAM16K2",
        "itemDescription": "זוג רגליים למינטו אלון מולבן 16 שמאל",
        "qty": 52.0,
        "location": "Warehouse A",
        "note1": "Note A",
        "note2": "Note B",
        "note3": "Note C",
        "note4": "Note D",
        "note5": "Note E",
        "itemAttributes": "Color: Red, Size: Medium"
    },
    {
        "logId": 2,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-04T18:29:24",
        "itemId": 3599,
        "itemSKU": "4MWPC0CLL13K1",
        "itemDescription": "זוג רגליים קלסיק למינטו לבן 13-16 י",
        "qty": 1.0,
        "location": "איפשהו",
        "note1": "מה זה החרא",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: קלסיק Color: למינטו לבן Height:  Direction: ימין"
    },
    {
        "logId": 3,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-04T23:36:51",
        "itemId": 3584,
        "itemSKU": "4MWPC0CLBL10K2",
        "itemDescription": "זוג רגליים קלסיק לינו פינו לבן 10-13 ש",
        "qty": 110.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 10 SubGroup: קלסיק Color: לינו פינו לבן Height:  Direction: שמאל"
    },
    {
        "logId": 4,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-04T23:41:30",
        "itemId": 3589,
        "itemSKU": "4MWPC0CLBL13K2",
        "itemDescription": "זוג רגליים קלסיק לינו פינו לבן 13-16 ש",
        "qty": 15.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: קלסיק Color: לינו פינו לבן Height:  Direction: שמאל"
    },
    {
        "logId": 5,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-04T23:45:10",
        "itemId": 3600,
        "itemSKU": "4MWPC0CLL13K2",
        "itemDescription": "זוג רגליים קלסיק למינטו לבן 13-16 ש",
        "qty": 50.0,
        "location": "Fooo",
        "note1": "עבר",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: קלסיק Color: למינטו לבן Height:  Direction: שמאל"
    },
    {
        "logId": 6,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-04T23:47:32",
        "itemId": 4877,
        "itemSKU": "4MWPCPRZB13X240K2",
        "itemDescription": "זוג רגליים פרימיום בז' 240\\13 שמאל",
        "qty": 17.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: פרימיום Color: למינטו בז' Height: 240 Direction: שמאל"
    },
    {
        "logId": 7,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:43:30",
        "itemId": 3588,
        "itemSKU": "4MWPC0CLBL13K1",
        "itemDescription": "זוג רגליים קלסיק לינו פינו לבן 13-16 י",
        "qty": 10.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: קלסיק Color: לינו פינו לבן Height: 206 Direction: ימין"
    },
    {
        "logId": 8,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:46:21",
        "itemId": 3715,
        "itemSKU": "4MWPC0EXCRM10K1",
        "itemDescription": "זוג רגליים אקסק. שמנת 10-13/206 י",
        "qty": 51.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 10 SubGroup: אקסקלוסיב Color: שמנת Height: 206 Direction: ימין"
    },
    {
        "logId": 9,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:48:03",
        "itemId": 3595,
        "itemSKU": "4MWPC0CLL10K1",
        "itemDescription": "זוג רגליים קלסיק למינטו לבן 10-13 י",
        "qty": 34.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 10 SubGroup: קלסיק Color: למינטו לבן Height:  Direction: ימין"
    },
    {
        "logId": 10,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:49:09",
        "itemId": 3599,
        "itemSKU": "4MWPC0CLL13K1",
        "itemDescription": "זוג רגליים קלסיק למינטו לבן 13-16 י",
        "qty": 50.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: קלסיק Color: למינטו לבן Height:  Direction: ימין"
    },
    {
        "logId": 11,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:50:15",
        "itemId": 3746,
        "itemSKU": "4MWPC0EXIW10X215K2",
        "itemDescription": "זוג רגליים אקסקלוסיב אייריש 215\\10 שמאל",
        "qty": 10.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 10 SubGroup: אקסקלוסיב Color: אייריש Height: 215 Direction: שמאל"
    },
    {
        "logId": 12,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T06:53:17",
        "itemId": 4586,
        "itemSKU": "4MWPCPRMK13X270K2",
        "itemDescription": "זוג רגליים פרימיום מוקה 270\\13 שמאל",
        "qty": 5.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: פרימיום Color: מוקה Height: 270 Direction: שמאל"
    },
    {
        "logId": 13,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T20:46:19",
        "itemId": 4452,
        "itemSKU": "4MWPCPRCTL12X240K2",
        "itemDescription": "זוג רגליים פרימיום קנטו לבן 240\\12 שמאל",
        "qty": 4.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[MainProductGroup: זוג רגליים Section: 12 SubProductGroup: פרימיום Color: קנטו לבן Height: 240 Direction: שמאל"
    },
    {
        "logId": 14,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-05T21:03:25",
        "itemId": 3576,
        "itemSKU": "4MWPC0CLAM3D13K1",
        "itemDescription": "זוג רגליים קלסיק אלון מולבן 3 דיפ 13-16/206 י",
        "qty": 8.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[MainProductGroup: זוג רגליים Section: 13 SubProductGroup: קלסיק Color: למינטו אלון מולבן Height: 206 Direction: ימין"
    },
    {
        "logId": 15,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-06T09:59:32",
        "itemId": 3683,
        "itemSKU": "4MWPC0EXBB13X215K1",
        "itemDescription": "זוג רגליים אקסקלוסיב לינו פינו בז' 215\\13 ימין",
        "qty": 3.0,
        "location": "",
        "note1": "4MELAM10K1 4MELAM10K2 4MELAM10RG208 4MELAM12K1 4MELAM12K2 4MELAM12RG208 4MELAM14K1 4MELAM14K2 4MELAM14RG208 4MELAM16K1",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: אקסקלוסיב Color: לינו פינו בז' Height: 215 Direction: ימין"
    },
    {
        "logId": 16,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-06T21:36:31",
        "itemId": 3753,
        "itemSKU": "4MWPC0EXIW13X215K2",
        "itemDescription": "זוג רגליים אקסקלוסיב אייריש 215\\13 שמאל",
        "qty": 690.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: אקסקלוסיב Color: אייריש Height: 215 Direction: שמאל"
    },
    {
        "logId": 17,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-07T09:33:46",
        "itemId": 3705,
        "itemSKU": "4MWPC0EXBL13X215K1",
        "itemDescription": "זוג רגליים אקסקלוסיב לינו פינו לבן 215\\13 ימין",
        "qty": 100.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 13 SubGroup: אקסקלוסיב Color: לינו פינו לבן Height: 215 Direction: ימין"
    },
    {
        "logId": 18,
        "countEventId": 1,
        "countEvenName": "Shay Event  new",
        "userId": 3,
        "firstName": "Alex",
        "lastName": "Sirota",
        "dateTime": "2024-04-09T17:32:56",
        "itemId": 3720,
        "itemSKU": "4MWPC0EXCRM10X215K1",
        "itemDescription": "זוג רגליים אקסקלוסיב שמנת 215\\10 ימין",
        "qty": 100.0,
        "location": "",
        "note1": "",
        "note2": "",
        "note3": "",
        "note4": "",
        "note5": "",
        "itemAttributes": "Attributes[Section: 10 SubGroup: אקסקלוסיב Color: שמנת Height: 215 Direction: ימין"
    }
]