import { Box, CircularProgress, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import { Check, Save } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { CHUser, CountLogDTO } from "../tsClasses/CHTypes";
import { getCurrentDateTime } from "../functions/GeneralFunc";

interface DataGridDemo4ActionsProps {
  params: any; // Define the type for params according to your requirements
  rowId: any; // Define the type for rowId according to your requirements
  setRowId: React.Dispatch<React.SetStateAction<any>>; // Define the type for setRowId according to your requirements
  userToken: string | null; // Define the type for userToken according to your requirements
  user: CHUser | null;
}

const DataGridDemo4Actions: React.FC<DataGridDemo4ActionsProps> = ({
  params,
  rowId,
  setRowId,
  userToken,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  function delay(ms: number | undefined) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const handleSubmit = async () => {
    setLoading(true);
    console.log(updatedLog);
    console.log(params);
    await delay(1500);

    setLoading(false);
    setSuccess(true);
    setRowId(-1);

    setLoading(false);
  };

  const updatedLog: CountLogDTO = {
    logId: params.id,
    qty: params.row.qty,
    note5:
      "Edited On " +
      getCurrentDateTime() +
      " By " +
      user?.firstName +
      " " +
      user?.lastName +
      " QTY " +
      params.qty +
      " | " +
      params.row.note5,
    countEventId: 0,
    countEvenName: "",
    userId: 0,
    firstName: "",
    lastName: "",
    dateTime: "",
    itemId: 0,
    itemSKU: "",
    itemDescription: "",
    location: "",
    note1: "",
    note2: "",
    note3: "",
    note4: "",
    itemAttributes: "",
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
          }}
          disabled={params.id !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default DataGridDemo4Actions;