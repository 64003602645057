import { CountEvent } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

const FetchAllCountEvents = async (userToken: string) => {
  console.log("FetchAllCountEvents user token:" + userToken);
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", token: userToken },
  };

  let empty: CountEvent[] = [];

  try {
    const response = await fetch(
      apiPath + "/getAllCountEvents",
      requestOptions
    );
    if (!response.ok) {
      console.log("FetchAllCountEvents - Something went wrong!");
      throw new Error("FetchAllCountEvents: Failed to fetch data ");
    }
    const data: CountEvent[] = await response.json();
    return data;
  } catch (error) {
    console.error("FetchAllCountEvents fetching data from :", error);

    return empty;
  }
};

export default FetchAllCountEvents;
