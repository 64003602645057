import { CHUser, CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath, getCurrentDateTime } from "./GeneralFunc";

type AddCountLogFuncProps = {
  logid: number;
  user: CHUser;
  qty: number;
  note5: string;
  userToken: string;
};

const UpdateCountLogFunc = async (props: AddCountLogFuncProps) => {
  console.log(props);
  console.log("UpdateCountLogFunc: token" + props.userToken);

  const countLogDTO: CountLogDTO = {
    logId: props.logid,
    qty: props.qty,
    note5:
      "Edited On " +
      getCurrentDateTime() +
      " By " +
      props.user.firstName +
      " " +
      props.user.lastName +
      " QTY " +
      props.qty ,
    countEventId: 0,
    countEvenName: "",
    userId: 0,
    firstName: "",
    lastName: "",
    dateTime: "",
    itemId: 0,
    itemSKU: "",
    itemDescription: "",
    location: "",
    note1: "",
    note2: "",
    note3: "",
    note4: "",
    itemAttributes: "",
  };

  console.log("moneytt2222" + countLogDTO.logId);
  const dataToSend = countLogDTO;

  console.log(dataToSend);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: props.userToken },
    body: JSON.stringify(dataToSend),
  };
  try {
    const response = await fetch(apiPath + "/updateCountLog", requestOptions);
    if (response.status == 401) {
      console.log("UpdateCountLogFunc: Something went wrong!");
      throw new Error("Unauthorized");
       ;
    }
    if (response == null) {
      console.log("UpdateCountLogFunc: Something went wrong!");
      throw new Error ("Something went wrong...")
    }

    const result = await response.text();
    console.log("UpdateCountLogFunc RESULT: " + result);
    return result;
  } catch (error) {
    throw new Error ("Something went wrong..."+error)
  }
};

export default UpdateCountLogFunc;
