import React, { PropsWithChildren } from "react";
import classes from "./CHeader.module.css";

type Props = {
  type: 1 | 2 | 3;
};

const CHeader: React.FC<PropsWithChildren<Props>> = (props) => {
  const cHeaderStyle = () => {
    if (props.type == 1) {
      return classes.c_header1;
    }

    if (props.type == 2) {
      return classes.c_header2;
    }

    if (props.type == 3) {
      return classes.c_header3;
    }
  };

  return <div className={cHeaderStyle()}>{props.children}</div>;
};

export default CHeader;
