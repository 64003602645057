export type CHUser = {
  userId: number;
  password: string;
  email: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  avatar: string;
  active: boolean;
};

export type Item = {
  itemId: number;
  itemSKU: string;
  itemDescription: string;
  mainCountGroup: MainCountGroup;
  mainProductGroup: string;
  color: string;
  section: string;
  subProductGroup: string;
  direction: string;
  material: string;
  height: string;
  active: boolean;
};

export type CountLogDTO = {
  logId: number;
  countEventId: number;
  countEvenName: string;
  userId: number;
  firstName: string;
  lastName: string;
  dateTime: string;
  itemId: number;
  itemSKU: string;
  itemDescription: string;
  qty: number;
  location: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  note5: string;
  itemAttributes: string;
};

export type CountEvent = {
  countEventId: number;
  countEventName: string;
  countEventDate: Date | number;
  itemsList: Item[];
  active: boolean;
};

export enum UserType {
  ADMINISTRATOR = "ADMINISTRATOR",
  MANAGER = "MANAGER",
  COUNTING = "COUNTING",
}

export enum MainCountGroup {
  COVERS = "Covers",
  EXTENDERS = "Extenders",
  FRAME_PROFILE = "Frame Profile",
  FRAMESET = "FrameSet",
  HEADS = "Heads",
  OTHER_PRODUCTS = "Other Products",
}

export function getUserTypeFromString(str: string): UserType {
  if (Object.values(UserType).includes(str as UserType)) {
    return str as UserType;
  }
  return UserType.COUNTING;
}

