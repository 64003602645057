import { CountEvent, CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

type AddItemsToCountEventProps = {
  countEvenIdToAdd: number;
  countItemsList: string[];
  userToken: string;
};

const AddItemsToCountEvent = async (props: AddItemsToCountEventProps) => {
  console.log(props);
  console.log("AddItemsToCountEvent: token" + props.userToken);

  const dataToSend = {
    countEventId: props.countEvenIdToAdd,
    itemsSku: props.countItemsList,
  };
  console.log("Data" + JSON.stringify(dataToSend));

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: props.userToken },
    body: JSON.stringify(dataToSend),
  };
  try {
    const response = await fetch(
      apiPath + "/addItemsToCountEvent",
      requestOptions
    );
    if (response.status == 401) {
      console.log("AddItemsToCountEvent: Something went wrong!");
      return "Unauthorized";
    }
    if (response == null) {
      console.log("AddItemsToCountEvent: Something went wrong!");
    }

    const result = await response.text();
    console.log("AddItemsToCountEvent RESULT: " + result);
    return result;
  } catch (error) {
    return "gotErr";
  }
};

export default AddItemsToCountEvent;
