import { Typography, TextField, MenuItem, IconButton } from "@mui/material";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useDispatch, useSelector } from "react-redux";
import { login, setAvatar } from "../store/userSlice";
import LoginFunc from "../functions/LoginFunc";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  ErrorOutlineSharp,
  Token,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ButtonCo from "../modals/ButtonCo";
import BasicModal from "../modals/BasicModal";
import LoadingModal from "../modals/LoadingModal";
import classes from "./Form.module.css";
import { RootState } from "../store/store";
import { setActiveCountEvents } from "../store/dataSlice";
import FetchAllActiveCountEvents from "../functions/FetchAllActiveCountEvents";
import FetchAvatar from "../functions/FetchAvatar";

export default function Login() {
  console.log("Hallo from Login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEmailValid, setisEmailValid] = useState(false);
  const [isEmailBlur, setisEmailBlur] = useState(false);
  const [isNameBlur, setisNameBlur] = useState(false);
  const [isPasswordBlur, setisPasswordBlur] = useState(false);
  const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const location = useLocation();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const chUserFromRedux = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER;
    }
    return null;
  });

  if (chUserFromRedux != null) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (isEmail(event.target.value)) {
      setisEmailValid(true);
    } else {
      setisEmailValid(false);
    }

    setInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const errorHnadler2 = () => {
    setisErrorModalOpen(true);
  };
  const newOnClickHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let dataUser;

    if (isEmailValid) {
      setisLoadingModalOpen(true);
      LoginFunc(inputs).then((res) => {
        setisLoadingModalOpen(false);
        if (res != "Error" && res != "gotError") {
          console.log("newOnClickHandle : RES = " + res);
          dataUser = JSON.parse(res);
          console.log(dataUser.tokenString);
          console.log(dataUser.chuser.avatar);
          dispatch(login(dataUser));

          FetchAvatar(dataUser.chuser.avatar, dataUser.tokenString!).then(
            async (res) => {
              if (res != null) {
                console.log("res: " + res);
                const imageUrl = URL.createObjectURL(res);
                console.log("dataURL:" + imageUrl);
                dispatch(setAvatar(imageUrl));
              }
            }
          );

          FetchAllActiveCountEvents(dataUser.tokenString).then((res2) => {
            if (res2 != "Error") {
              console.log("FetchAllClients " + res2);
              dispatch(setActiveCountEvents(JSON.parse(res2)));
            }
            if (res2 == "Error") {
              console.log(res2);
            }
          });

          setInputs({
            email: "",
            password: "",
          });

          navigate("/SelectCountEvent");
        }

        if (res == "Error") {
          setErrorTitle("Incorrect password or username");
          setErrorMessage(
            "Please check your username and password and try again"
          );
          setisErrorModalOpen(true);
          console.log(isErrorModalOpen);
        }

        if (res == "gotError" || res.includes("Error")) {
          console.log("on error model");
          setErrorTitle("Server issues");
          setErrorMessage("Please try again in few minutes");
          setisErrorModalOpen(true);
          console.log(isErrorModalOpen);
        }
      });
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setshowPassword(false);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    console.log("gotPassChange");
    setInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    console.log("setedPassChange");
  };

  const valid = isEmailValid && inputs.password.length > 1;

  return (
    <div>
      <LoadingModal open={isLoadingModalOpen}></LoadingModal>
      <BasicModal
        open={isErrorModalOpen}
        onClose={() => setisErrorModalOpen(false)}
        onConfirm={() => setisErrorModalOpen(false)}
        title={errorTitle}
        message={errorMessage}
      />
      <div className={classes.formHeader}>Sign in</div>
      <form>
        <TextField
          error={isEmailBlur && isEmailValid === false}
          onBlur={() => setisEmailBlur(true)}
          value={inputs.email}
          name="email"
          placeholder=""
          helperText="(Required)"
          onChange={handleEmailChange}
          style={{ width: "400px", margin: "25px" }}
          label="User E-mail"
          variant="outlined"
        />
        <br />
        <TextField
          value={inputs.password}
          name="password"
          onBlur={() => setisPasswordBlur(true)}
          onChange={handlePasswordChange}
          style={{ width: "400px", margin: "25px" }}
          type={showPassword ? "text" : "password"}
          label="User Password"
          helperText="(Required)"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={() => setshowPassword(true)}
                onMouseUp={() => setshowPassword(false)}
                onMouseLeave={() => setshowPassword(false)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        <br />
        <br />
        <ButtonCo type="submit" onClick={newOnClickHandle} disabled={!valid}>
          Login
        </ButtonCo>
      </form>
    </div>
  );
}
