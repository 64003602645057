// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_formHeader__h7dJc {
    color: #0c2d57;
    text-align: center;
    font-size: 50px;
    font-weight: 700; 
    margin-top: 10vh;
  }

  .Form_main_div__uQ0xv {

    margin-top: 4vh;
    text-align: center;
    font-size: xx-large;
    color: #0c2d57;
    font-weight: 900;
    align-items: center;

}

.Form_main_form_div__CTYbR {

  max-width: 300px;
  margin: auto;
}


`, "",{"version":3,"sources":["webpack://./src/pages/Form.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;;IAEE,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;;EAEE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".formHeader {\n    color: #0c2d57;\n    text-align: center;\n    font-size: 50px;\n    font-weight: 700; \n    margin-top: 10vh;\n  }\n\n  .main_div {\n\n    margin-top: 4vh;\n    text-align: center;\n    font-size: xx-large;\n    color: #0c2d57;\n    font-weight: 900;\n    align-items: center;\n\n}\n\n.main_form_div {\n\n  max-width: 300px;\n  margin: auto;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formHeader": `Form_formHeader__h7dJc`,
	"main_div": `Form_main_div__uQ0xv`,
	"main_form_div": `Form_main_form_div__CTYbR`
};
export default ___CSS_LOADER_EXPORT___;
