import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Grid,
  SelectChangeEvent,
  Typography,
  RadioProps,
} from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./CountForms.module.css";
import { RootState } from "../store/store";
import {
  CHUser,
  CountEvent,
  CountLogDTO,
  Item,
  MainCountGroup,
} from "../tsClasses/CHTypes";
import LoadingModal from "../modals/LoadingModal";
import SuccessModal from "../modals/SuccessModal";
import ButtonCo from "../modals/ButtonCo";
import { getCurrentDateTime } from "../functions/GeneralFunc";
import AddCountLogFunc from "../functions/AddCountLogFunc";
import ErrorModal from "../modals/ErrorModal";
import { setCurrentCountLogs } from "../store/dataSlice";
import { useDispatch } from "react-redux";
import RadioGroupCo from "../modals/RadioGroupCo";
import { CheckLogIn } from "../functions/CheckLogIn";
import FetchAllCurrentCountLogsDTO from "../functions/FetchAllCurrentCountLogsDTO";

const CoverCountForm = () => {
  const [mainProductGroup, setMainProductGroup] = useState("הלבשה");
  const [section, setSection] = useState("");
  const [subProductGroup, setSubProductGroup] = useState("");
  const [color, setColor] = useState("");
  const [height, setHeight] = useState("");
  const [direction, setDirection] = useState("");
  const [items, setItems] = useState<Item[]>([]);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [quantity, setQuantity] = useState(0);
  const [quantityManipolator, setQuantityManipolator] = useState(1);
  const [manipolator, setManipolator] = useState("1");
  const [sku, setSKU] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemId, setItemId] = useState(0);
  const [locationNote, setLocationNote] = useState("");
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const [isSuccessModallOpen, setiIsSuccessModallOpen] = useState(false);
  const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState("");
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [note4, setNote4] = useState("");
  const [note5, setNote5] = useState("");

  const dispatch = useDispatch();

  

  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const user: CHUser | null = useSelector(
    (state: RootState) => state.user.chUSER
  );
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  const countGroup: MainCountGroup = MainCountGroup.FRAMESET;

  useEffect(() => {
    if (currentCount) {
      const filteredItems = currentCount.itemsList.filter(
        (item) => item.mainProductGroup === mainProductGroup
      );
      setItems(filteredItems);
      setFilteredItems(filteredItems);
    }
  }, [currentCount, mainProductGroup]);

  type CustomLabels = {
    [key: string]: string;
  };

  const handleMainProductGroupChange = (event: SelectChangeEvent<string>) => {
    const selectedMainProductGroup = event.target.value as string;
    setMainProductGroup(selectedMainProductGroup);
    filterItems(
      selectedMainProductGroup,
      section,
      subProductGroup,
      color,
      direction,
      height
    );
  };

  const handleSectionChange = (event: SelectChangeEvent<string>) => {
    const selectedSection = event.target.value as string;
    setSection(selectedSection);
    filterItems(
      selectedSection,
      subProductGroup,
      color,
      direction,
      height,
      mainProductGroup
    );
  };

  const handleSectionChangeRadioButten = (selectedValue: string) => {
    const selectedSection = selectedValue;
    setSection(selectedSection);
    filterItems(
      selectedSection,
      subProductGroup,
      color,
      direction,
      height,
      mainProductGroup
    );
  };

  const handleSubProductGroupChange = (event: SelectChangeEvent<string>) => {
    const selectedSubGroup = event.target.value as string;
    setSubProductGroup(selectedSubGroup);
    filterItems(
      section,
      selectedSubGroup,
      color,
      direction,
      height,
      mainProductGroup
    );
  };

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    const selectedColor = event.target.value as string;
    setColor(selectedColor);
    filterItems(
      section,
      subProductGroup,
      selectedColor,
      direction,
      height,
      mainProductGroup
    );
  };

  const handledirectionChange = (selectedValue: string) => {
    const selctedDirection = selectedValue;
    setDirection(selctedDirection);
    filterItems(
      section,
      subProductGroup,
      color,
      selctedDirection,
      height,
      mainProductGroup
    );
  };

  const handleHeightChange = (selectedValue: string) => {
    const selectedHeight = selectedValue;
    setHeight(selectedHeight);
    filterItems(
      section,
      subProductGroup,
      color,
      direction,
      selectedHeight,
      mainProductGroup
    );
  };

  const handleQuantityManipulatorChange = (selectedValue: string) => {
    let manipulator2 = 1;

    manipulator2 = parseFloat(selectedValue);
    setManipolator(selectedValue);
    setQuantityManipolator(manipulator2);
  };

  const handleLocationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLocationNote(event.target.value);
  };

  const handleNoteChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNote1(event.target.value);
  };

  const handleClearForm = () => {
    const islog = CheckLogIn(userToken);
    console.log(islog);
    setSection("");
    setSubProductGroup("");
    setColor("");
    setHeight("");
    setDirection("");
    setFilteredItems(items);
    setQuantity(0);
    setSKU("");
    setNote1("");
    setLocationNote("");
    setItemDescription("");
  };

  const handleClearAfterSubmit = () => {
    setHeight("");
    setColor("");
    setDirection("");
    setQuantity(0);
    setSKU("");
    setItemDescription("");
    filterItems(
      section,
      subProductGroup,
      color,
      direction,
      height,
      mainProductGroup
    );
  };

  const countLogDTO: CountLogDTO =
    sku !== ""
      ? {
          logId: 0,
          countEventId: currentCount.countEventId,
          countEvenName: currentCount.countEventName,
          userId: user!.userId,
          firstName: user!.firstName,
          lastName: user!.lastName,
          dateTime: getCurrentDateTime(),
          itemId: itemId,
          itemSKU: sku,
          itemDescription: itemDescription,
          qty: quantity * quantityManipolator,
          location: locationNote,
          note1: note1,
          note2: note2,
          note3: note3,
          note4: note4,
          note5: note5,
          itemAttributes: `Attributes[MainProductGroup: ${mainProductGroup} Section: ${section} SubProductGroup: ${subProductGroup} Color: ${color} Height: ${height} Direction: ${direction} Manipolator: ${manipolator} = ${quantityManipolator}`,
        }
      : {
          logId: 0,
          countEventId: 0,
          countEvenName: "",
          userId: 0,
          firstName: "",
          lastName: "",
          dateTime: "",
          itemId: 0,
          itemSKU: "",
          itemDescription: "",
          qty: 0,
          location: "",
          note1: "",
          note2: "",
          note3: "",
          note4: "",
          note5: "",
          itemAttributes: "",
        };

  const printlog = () => {
    if (countLogDTO) {
      console.log("CountLogDTO Properties:");
      for (const prop in countLogDTO) {
        if (Object.prototype.hasOwnProperty.call(countLogDTO, prop)) {
          console.log(`${prop}: ${(countLogDTO as any)[prop]}`);
        }
      }
    } else {
      console.log("countLogDTO is null");
    }
  };

  const handelOnSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!(sku == "" || quantity == 0)) {
      setisLoadingModalOpen(true);
      printlog();
      AddCountLogFunc({ countLogDTO, userToken }).then((res) => {
        setisLoadingModalOpen(false);
        if (!res.includes("Error") || res == "gotErr") {
          FetchAllCurrentCountLogsDTO(
            currentCount.countEventId,
            userToken
          ).then(async (res3) => {
            if (res3 != null) {
              console.log("All Companies List" + res3);
              dispatch(setCurrentCountLogs(res3));
              setiIsSuccessModallOpen(true);
              await delay(1500);
              setiIsSuccessModallOpen(false);
              handleClearAfterSubmit();
            }
          });
        }

        if (res.includes("Error")) {
          console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
          let error = JSON.parse(res);
          let errorMSG = error.value;
          setErrorTitle("Error");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "Unauthorized") {
          console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
          let errorMSG = "Unauthorized Action";
          setErrorTitle("Unauthorized");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "gotErr") {
          setErrorTitle("Server issues");
          setErrorMessage("Please try again in few minutes");
          setisErrorModalOpen(true);
          console.log(res);
        }
      });
    }
  };

  function delay(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  const filterItems = (
    selectedSection: string,
    selectedSubGroup: string,
    selectedColor: string,
    selectedDirection: string,
    selectedHeight: string,
    selectedMainProductGroup: String
  ) => {
    let newFilteredItems = items;

    if (selectedMainProductGroup !== "") {
      newFilteredItems = newFilteredItems.filter(
        (item) => item.mainProductGroup === selectedMainProductGroup
      );
      if (selectedSection !== "") {
        newFilteredItems = newFilteredItems.filter(
          (item) => item.section === selectedSection
        );
      }
      if (selectedSubGroup !== "") {
        newFilteredItems = newFilteredItems.filter(
          (item) => item.subProductGroup === selectedSubGroup
        );
      }
      if (selectedColor !== "") {
        newFilteredItems = newFilteredItems.filter(
          (item) => item.color === selectedColor
        );
      }

      if (selectedHeight !== "") {
        newFilteredItems = newFilteredItems.filter(
          (item) => item.height === selectedHeight
        );
      }

      if (selectedDirection !== "") {
        newFilteredItems = newFilteredItems.filter(
          (item) => item.direction === selectedDirection
        );
      }
      if (newFilteredItems.length == 1) {
        setSKU(newFilteredItems.at(0)!.itemSKU);
        setItemId(newFilteredItems.at(0)!.itemId);
        setItemDescription(newFilteredItems.at(0)!.itemDescription);
      } else {
        setSKU("");
        setItemId(0);
        setItemDescription("");
      }

      setFilteredItems(newFilteredItems);
    }
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(event.target.value));
  };

  const customLabels: CustomLabels = {
    formName: "טופס ספירת הלבשות",
    colorInputLabel: "גוון",
    mainProductGroupInputLabel: "קבוצה ראשית",
    sectionInputLabel: "חתך",
    subGroupInputLabel: "קבוצת מוצר",
    heightInputLabel: "גובה",
    directionInputLabel: "כיוון",
    materialInputLabel: "חומר",
    manuItemClearChioce: "נקה בחירה",
    heightRadioGruopCoLabel: "אורך",
    heightHintText: "הכנס אורך",
    color: "inline",
    height: "גובה",
    sku: "מק''ט",
    itemDescription: "תיאור פריט",
  };

  const showmainProductGroupSelectGrid: boolean = false;
  const showsubGroupSelectGrid: boolean = true;
  const showSectionSelectGrid: boolean = true;
  const showSectionRadioGrupGrid: boolean = false;
  const showColorSelectGrid: boolean = true;
  const showDirectionadioGrupGrid: boolean = false;
  const showHeightRadioGrupGrid: boolean = true;
  const showManipolatorRadioGrupGrid: boolean = true;

  const quantityManipolatorValues = ["0.5", "1", "2.5", "3", "5"];

  if (currentCount.itemsList.at(0) == null) {
    return (
      <div>
        <div className={classes.formHeader}>{customLabels.formName}</div>
        <h3>לא נמצאו פריטים לספירה</h3>
      </div>
    );
  } else {
    return (
      <>
        <LoadingModal open={isLoadingModalOpen}></LoadingModal>
        <SuccessModal open={isSuccessModallOpen} />
        <ErrorModal
          open={isErrorModalOpen}
          onClose={() => setisErrorModalOpen(false)}
          onConfirm={() => setisErrorModalOpen(false)}
          title={errorTitle}
          message={errorMessage}
        />
        <div className={classes.formHeader}>{customLabels.formName}</div>
        <div className={classes.main_form_div}>
          <Grid container spacing={2}>
            {showmainProductGroupSelectGrid ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="mainProductGroup-label">
                    {customLabels.mainProductGroupInputLabel}
                  </InputLabel>
                  <Select
                    label="Product Family"
                    labelId="mainProductGroup-label"
                    id="mainProductGroup"
                    value={mainProductGroup}
                    onChange={handleMainProductGroupChange}
                    fullWidth
                  >
                    <MenuItem value="" style={{ color: "#FA7070" }}>
                      {customLabels.manuItemClearChioce}
                    </MenuItem>
                    {[
                      ...new Set(
                        filteredItems
                          .map((item) => item.mainProductGroup)
                          .flat()
                      ),
                    ].map((mainProductGroup, index) => (
                      <MenuItem key={index} value={mainProductGroup}>
                        {mainProductGroup}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {showsubGroupSelectGrid ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="subProductGroup-label">
                    {customLabels.subGroupInputLabel}
                  </InputLabel>
                  <Select
                    label="Product Family"
                    labelId="subProductGroup-label"
                    id="subProductGroup"
                    value={subProductGroup}
                    onChange={handleSubProductGroupChange}
                    fullWidth
                  >
                    <MenuItem value="" style={{ color: "#FA7070" }}>
                      {customLabels.manuItemClearChioce}
                    </MenuItem>
                    {[
                      ...new Set(
                        filteredItems.map((item) => item.subProductGroup).flat()
                      ),
                    ].map((subGroup, index) => (
                      <MenuItem key={index} value={subGroup}>
                        {subGroup}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {showSectionSelectGrid ? (
              <Grid item xs={12}>
                <FormControl fullWidth style={{ textAlign: "center" }}>
                  <InputLabel id="section-label">
                    {customLabels.sectionInputLabel}
                  </InputLabel>
                  <Select
                    label="Section"
                    labelId="section-label"
                    id="section"
                    value={section}
                    onChange={handleSectionChange}
                  >
                    <MenuItem value="" style={{ color: "#FA7070" }}>
                      {customLabels.manuItemClearChioce}
                    </MenuItem>
                    {[
                      ...new Set(
                        filteredItems.map((item) => item.section).flat()
                      ),
                    ].map((section, index) => (
                      <MenuItem key={index} value={section}>
                        {section}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {showSectionRadioGrupGrid ? (
              <Grid item xs={12}>
                <RadioGroupCo
                  name="sectionRadioGrup"
                  options={[
                    "",
                    ...new Set(
                      filteredItems
                        .map((item) => item.section)
                        .flat()
                        .sort()
                    ),
                    "",
                  ]}
                  onChange={handleSectionChangeRadioButten}
                  value={section}
                />
              </Grid>
            ) : null}

            {showColorSelectGrid ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="color-label">
                    {customLabels.colorInputLabel}
                  </InputLabel>
                  <Select
                    label="Color"
                    labelId="color-label"
                    id="color"
                    value={color}
                    onChange={handleColorChange}
                    fullWidth
                  >
                    <MenuItem value="" style={{ color: "#FA7070" }}>
                      {customLabels.manuItemClearChioce}
                    </MenuItem>
                    <MenuItem value="">{customLabels.colorManuItem}</MenuItem>
                    {[
                      ...new Set(
                        filteredItems.map((item) => item.color).flat()
                      ),
                    ].map((color, index) => (
                      <MenuItem key={index} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {showDirectionadioGrupGrid ? (
              <Grid item xs={12}>
                <RadioGroupCo
                  name="direction"
                  options={[
                    "",
                    ...new Set(
                      filteredItems.map((item) => item.direction).flat()
                    ),
                    "",
                  ]}
                  onChange={handledirectionChange}
                  value={direction}
                />
              </Grid>
            ) : null}

            {showHeightRadioGrupGrid ? (
              <Grid item xs={12}>
                <RadioGroupCo
                  name="height"
                  options={[
                    "",
                    ...new Set(filteredItems.map((item) => item.height).flat()),
                    "",
                  ]}
                  onChange={handleHeightChange}
                  value={height}
                />
              </Grid>
            ) : null}

            {showManipolatorRadioGrupGrid ? (
              <Grid item xs={12}>
                <RadioGroupCo
                  name="manipolator"
                  options={["", ...quantityManipolatorValues, ""]}
                  onChange={handleQuantityManipulatorChange}
                  value={manipolator}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={quantity}
                  name="Qty"
                  placeholder=""
                  helperText="(שדה חובה)"
                  onChange={handleQuantityChange}
                  label="כמות"
                />
              </FormControl>
            </Grid>
            <div className={classes.sku_div}>
              {sku}
              <br />
              {itemDescription}
            </div>
            <TextField
              value={locationNote}
              name="location1"
              onChange={handleLocationChange}
              style={{ width: "400px", margin: "25px" }}
              type="text"
              label="מיקום"
              variant="outlined"
            />
            <TextField
              value={note1}
              name="note"
              onChange={handleNoteChange}
              style={{ width: "400px", margin: "25px" }}
              type="text"
              label="הערה"
              variant="outlined"
            />
            <Grid item xs={12}>
              <ButtonCo
                type={"submit"}
                onClick={handelOnSubmit}
                disabled={sku == "" || quantity == 0 ? true : false}
              >
                שגר
              </ButtonCo>
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleClearForm}>
                נקה טופס
              </Button>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
};

export default CoverCountForm;
