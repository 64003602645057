// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountEventCard_maindiv__wuxA8 {
    margin: auto;
    margin-bottom: 15px;
    padding-top: 25px;
    height: 6rem;
  min-width: 300px;
  width: -moz-fit-content;
  width: fit-content;
    align-items: stretch;
    box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);
    border-radius: 6px;
    color: #0c2d57;
    background-color: #78C1F3;
    cursor: pointer;

  }
    
  .CountEventCard_count_date__RNiWY {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .CountEventCard_count_event_name__WVNpb {
    font-size: 1.25rem;
    font-weight: 700;
    width: calc (inherit-0.5rem);
  }

  @media (min-width: 768px) {
    .CountEventCard_modal__kx6az {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/cards/CountEventCard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;EACd,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;IAChB,oBAAoB;IACpB,gDAAgD;IAChD,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,eAAe;;EAEjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;EAC9B;;EAEA;IACE;MACE,uBAAuB;MACvB,YAAY;IACd;EACF","sourcesContent":[".maindiv {\n    margin: auto;\n    margin-bottom: 15px;\n    padding-top: 25px;\n    height: 6rem;\n  min-width: 300px;\n  width: fit-content;\n    align-items: stretch;\n    box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);\n    border-radius: 6px;\n    color: #0c2d57;\n    background-color: #78C1F3;\n    cursor: pointer;\n\n  }\n    \n  .count_date {\n    margin-top: 20px;\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  \n  .count_event_name {\n    font-size: 1.25rem;\n    font-weight: 700;\n    width: calc (inherit-0.5rem);\n  }\n\n  @media (min-width: 768px) {\n    .modal {\n      left: calc(50% - 20rem);\n      width: 40rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maindiv": `CountEventCard_maindiv__wuxA8`,
	"count_date": `CountEventCard_count_date__RNiWY`,
	"count_event_name": `CountEventCard_count_event_name__WVNpb`,
	"modal": `CountEventCard_modal__kx6az`
};
export default ___CSS_LOADER_EXPORT___;
