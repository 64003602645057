import { useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CHeader from "../button/CHeader";

import { heIL } from "@mui/x-data-grid/locales";
import { Item } from "../tsClasses/CHTypes";

interface ItemListModalProps {
  itemsList: Item[];
  countEventName: String;
}

export default function ItemListModal({
  itemsList,
  countEventName,
}: ItemListModalProps): JSX.Element | null {
  const initialRows: GridRowsProp = itemsList.map((x) => ({
    itemSku: x.itemSKU,
    itemDescription: x.itemDescription,
    mainProductGroup: x.mainProductGroup,
  }));

  useEffect(() => {
    setRows(initialRows);
  }, [itemsList]);

  const [rows, setRows] = useState(initialRows);

  console.log("Shayyyyyyyyyyyyyyyyy" + countEventName);
  console.log("Assssssssssssss" + rows);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "מזהה",
      width: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "itemSku",
      headerName: "קוד פריט",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "itemDescription",
      headerName: "תאריך",
      width: 450,
      align: "right",
      headerAlign: "center",
    },
    {
      field: "mainProductGroup",
      headerName: "קבוצה ראשית",
      width: 200,
      align: "center",
    },
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(itemsList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "exportedData.xlsx");
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <img
          src="./images/icons/xls.png"
          style={{
            height: "30px",
            cursor: "pointer",
          }}
          onClick={exportToExcel}
        />
      </GridToolbarContainer>
    );
  }

  let header = "רשימת פריטים " + countEventName;

  return (
    <div
      style={{
        maxWidth: "100%",
        padding: "0px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <CHeader type={2}>{header}</CHeader>
      <DataGrid
        slots={{ toolbar: CustomToolbar }}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.itemSku}
        autoHeight
        sx={{
          boxShadow: 2,
          align: "center",
          flexGrow: 1,
        }}
      />
    </div>
  );
}
