import { CHUser } from "../../tsClasses/CHTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import classes from "./SelectUser.module.css";
import UserCard from "../../cards/UserCard";
import { Link } from "react-router-dom";
import { useState } from "react";
import { TextField } from "@mui/material";

interface SelectUserProps {
  usersList: CHUser[];
  onClick?: (user: CHUser) => void;
  actionType?: string;
}

const SelectUser = (props: SelectUserProps) => {
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  const [filterText, setFilterText] = useState("");

  const filteredUsers = props.usersList.filter((user) =>
    Object.values(user).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const onClickHandler = (user: CHUser) => {
    if (props.onClick) {
      props.onClick(user);
    }
  };

  console.log("Hello from SelectUser");
  if (props.usersList.at(0) == null) {
    return (
      <div className={classes.no_items_div}> No users to display here</div>
    );
  } else {
    return (
      <div className={classes.main_list_div}>
        <div className={classes.formHeader}>בחר משתמש</div>
        <br />
        <TextField
          label="Search"
          value={filterText}
          onChange={handleFilterChange}
          variant="outlined"
          fullWidth
          className={classes.filterInput}
          sx={{ width: "50%" }}
        />
        <br />
        <div className={classes.div_item}>
          {filteredUsers.map((user) => (
            <UserCard
              key={user.userId}
              user={user}
              userToken={userToken}
              onClick={onClickHandler}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default SelectUser;
