import { CountEvent, MainCountGroup } from "../tsClasses/CHTypes";
import classes from "./MainProductGroupCard.module.css"
import Moment from "moment";

interface MainProductGroupCardProps {
    mainProductGroup  : string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    actionType?: string;
  }

  const MainProductGroupCard: React.FC<MainProductGroupCardProps> = ({ mainProductGroup, onClick, actionType }) => {
    console.log("Hello from MainCountGroupCard");


  return (
    <div className={classes.maindiv} onClick={onClick}>
    <div className={classes.main_count_group}>{mainProductGroup}</div>   
    </div>
  );

}

export default MainProductGroupCard;