import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { clearAllDataLists } from "../store/dataSlice";
import { logout } from "../store/userSlice";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

function UserAvatarMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserFromRedux = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER;
    }
    return null;
  });

  const avatarURL: string | null = useSelector(
    (state: RootState) => state.user.avatarURL
  );

  let userName = UserFromRedux?.firstName + " " + UserFromRedux?.lastName;
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleEditProfrileMenu = () => {
    setAnchorElUser(null);
    navigate("UpdateProfile");
  };

  const logOutOnClickHandler = () => {
    dispatch(logout());
    dispatch(clearAllDataLists());
    setAnchorElUser(null);
    navigate("/");
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0, marginLeft: "50px" }}
        >
          <Avatar alt="User" src={avatarURL} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: "45px",
          "& .MuiMenuItem-root": {
            display: "flex",
            justifyContent: "center",
          },
          "& hr": {
            width: "80%", // Adjust the width of the horizontal line as needed
            margin: "8px auto", // Center the horizontal line
          },
          "& .disable-hover:hover": {
            cursor: "default",
            backgroundColor: "inherit",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem className="disable-hover">
          <Avatar alt="User" src={avatarURL} sx={{ width: 100, height: 100 }} />
        </MenuItem>
        <MenuItem onClick={handleEditProfrileMenu}>
          <Typography textAlign="center">{userName}</Typography>
        </MenuItem>

        <hr />
        <MenuItem onClick={logOutOnClickHandler}>
          <Typography textAlign="center">התנתק</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserAvatarMenu;
