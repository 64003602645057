// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.SuccessModal_backdrop__BFB3F {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

.SuccessModal_modal__rUGre {
  position: fixed;
  top: 30vh;
  left: auto;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.SuccessModal_container__PvZdl {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  color: black;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SuccessModal_img45__GrF7A {
  width: 300px;
  height: 300px;
  object-fit: contain;
  transition: width 2s, height 4s;
}

@media (min-width: 768px) {
  .SuccessModal_modal__rUGre {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/SuccessModal.module.css"],"names":[],"mappings":";;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,4EAA4E;EAC5E,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF","sourcesContent":["\n\n.backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0);\n}\n\n.modal {\n  position: fixed;\n  top: 30vh;\n  left: auto;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n}\n\n.container {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n  padding: 1rem;\n  color: black;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  max-height: 40vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.img45 {\n  width: 300px;\n  height: 300px;\n  object-fit: contain;\n  transition: width 2s, height 4s;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `SuccessModal_backdrop__BFB3F`,
	"modal": `SuccessModal_modal__rUGre`,
	"container": `SuccessModal_container__PvZdl`,
	"img45": `SuccessModal_img45__GrF7A`
};
export default ___CSS_LOADER_EXPORT___;
