import { apiPath } from "./GeneralFunc";

const FetchAllActiveCountEvents = async (userToken: string) => {
  console.log("FetchAllActiveCountEvents user token:" + userToken);
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", token: userToken },
  };
  const response = await fetch(
    apiPath + "/getAllActiveCountEvents",
    requestOptions
  );
  if (!response.ok) {
    console.log("FetchAllActiveCountEvents - Something went wrong!");
  }
  const result = await response.text();
  console.log("FetchAllActiveCountEvents RESULT: " + result);
  return result;
};

export default FetchAllActiveCountEvents;
