import * as React from "react";
import {
  DataGrid,
  GridRowModel,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import { CHUser, CountEvent, CountLogDTO } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import CHeader from "../button/CHeader";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import UpdateCountLogFunc from "../functions/UpdateCountLogFunc";
import getAllCurrentCountLogsDTO from "../functions/FetchAllCurrentCountLogsDTO";
import { setCurrentCountLogs } from "../store/dataSlice";
import { heIL } from "@mui/x-data-grid/locales";

interface User {
  name: string;
  age: number;
  id: GridRowId;
  dateCreated: Date;
  lastLogin: Date;
}

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  if (newRow.qty !== oldRow.qty) {
    return `QTY from '${oldRow.qty}' to '${newRow.qty}'`;
  }
  if (newRow.note1 !== oldRow.note1) {
    return `Age from '${oldRow.note1 || ""}' to '${newRow.note1 || ""}'`;
  }
  return null;
}

export default function CountLogDataFGridExtended() {
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);
  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const user: CHUser | null = useSelector(
    (state: RootState) => state.user.chUSER
  );
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  const currentCountLogs2: CountLogDTO[] | null = useSelector(
    (state: RootState) => state.data.currentCountLogs
  );
  const dispatch = useDispatch();
  const initialRows: GridRowsProp = currentCountLogs2.map((x) => ({
    logId: x.logId,
    firstName: x.firstName,
    dateTime: new Date(x.dateTime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }),
    itemSKU: x.itemSKU,
    itemDescription: x.itemDescription,
    qty: x.qty,
    location: x.location,
    note1: x.note1,
    note5: x.note5,
    itemAttributes: x.itemAttributes,
  }));

  const [rows, setRows] = React.useState(initialRows);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      const propsToUpdate = {
        logid: newRow.logId,
        user: user!,
        qty: newRow.qty,
        note5: newRow.note5,
        userToken: userToken,
      };
      console.log("mopneyyyyyyyyyyyy" + propsToUpdate.logid);
      const response = await UpdateCountLogFunc(propsToUpdate);
      setSnackbar({
        children: "Log successfully updated",
        severity: "success",
      });
      getAllCurrentCountLogsDTO(currentCount!.countEventId, userToken).then((res) => {
        if (res != null) {
          dispatch(setCurrentCountLogs(res));
         window.location.reload();
        }
        if (res == null) {
          console.log("CurrentCountLog res is null");
        }
      });
      
      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "Log fails to save", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(currentCountLogs2);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "exportedData.xlsx");
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

   

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <img
        src="./images/icons/xls.png"
        style={{
          height:"30px",
          cursor: "pointer",
        }}
        onClick={exportToExcel}
      />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        width: "80%",
        padding: "0px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <CHeader type={1}>יומן תנועות</CHeader>
      <CHeader type={2}>{currentCount.countEventName}</CHeader>
      {renderConfirmDialog()}
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar, }}
         slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.logId}
        sx={{
          boxShadow: 2,
          align: "center",
          flexGrow: 1,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              location: false,
              itemAttributes: false,
              note5: false,
            },
          },
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}

const columns: GridColDef[] = [
  {
    field: "logId",
    headerName: "מזהה",
    width: 50,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "firstName",
    headerName: "שם סופר",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  { field: "dateTime", headerName: "תאריך/שעה", width: 180, align: "right" },
  { field: "itemSKU", headerName: 'מק"ט', width: 225, align: "right" },
  {
    field: "itemDescription",
    headerName: "תיאור פריט",
    width: 300,
    align: "right",
  },
  {
    field: "qty",
    headerName: "כמות",
    width: 65,
    align: "center",
    headerAlign: "center",
    editable: true,
  },
  { field: "location", headerName: "מיקום", width: 300, align: "right" },
  {
    field: "note1",
    headerName: "הערה",
    width: 100,
    align: "right",
    editable: true,
  },
  {
    field: "note5",
    headerName: "5 הערה",
    width: 100,
    align: "right",
    editable: false,
  },
  {
    field: "itemAttributes",
    headerName: "מאפיינים",
    width: 400,
    align: "right",
  },
];
