import "./ErrorPage.css";

function SessionTO() {
  console.log("Hello from SessionTO");
  return (
    <div className="errMessege">
      <div>Session timed out or disconnected</div>
      <div>Please sing in again</div>
    </div>
  );
}

export default SessionTO;
