import { apiPath } from "./GeneralFunc";
import axios from "axios";
import FormData from 'form-data';
import fs from 'fs';


async function uploadAvatar(token: string, dataURL: string): Promise<string | null> {

    try {
        const generateRandomFileName = (): string => {
          //  const extension = url.split('.').pop() || '';
            const randomChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let randomFileName = '';
            for (let i = 0; i < 8; i++) {
              randomFileName += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return randomFileName;
            
          };
    const fileName: string =  generateRandomFileName();
    const file:File = dataURLtoFile(dataURL)
    let data = new FormData();
    data.append('file',file);
    data.append('fileName', fileName);

    const myHeaders = {
        'token': token,
        'Content-Type': `multipart/form-data`
    };
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiPath+"/UploadAvatar",
      headers: {
        ...myHeaders,
    },
      data : data
    };
    

    const response = await axios.request(config);

        if (!response.data) {
            return null;
        }
      console.log("resoponseData:"+response.data);
        return response.data;
        

    } catch (error) {
        console.error('Error uploading avatar:', error);
        return null;
    }

    function dataURLtoFile(dataurl: string) {
        const base64ImageParts = dataurl.split(",");
        const contentType = base64ImageParts[0].split(":")[1];
        const rawImageData = atob(base64ImageParts[1]);
        const imageDataLength = rawImageData.length;
        const uint8Array = new Uint8Array(imageDataLength);
      
        for (let i = 0; i < imageDataLength; ++i) {
          uint8Array[i] = rawImageData.charCodeAt(i);
        }
      
        const blob = new Blob([uint8Array], { type: contentType });
      
        // Create a file from the Blob
        const file100 = new File([blob], "cropped_image.png", { type: contentType });
        return file100;
        }

   
}
  export default uploadAvatar;
  