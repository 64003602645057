import { CHUser, CountEvent } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

const FetchAllUsersFunc = async (userToken: string) => {
  console.log("FetchAllUsersFunc user token:" + userToken);
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", token: userToken },
  };

  let empty: CHUser[] = [];

  try {
    const response = await fetch(apiPath + "/getAllUsers", requestOptions);
    if (!response.ok) {
      console.log("FetchAllUsersFunc - Something went wrong!");
      throw new Error("FetchAllUsersFunc: Failed to fetch data ");
    }
    const data: CHUser[] = await response.json();
    return data;
  } catch (error) {
    console.error("FetchAllUsersFunc fetching data from :", error);

    return empty;
  }
};

export default FetchAllUsersFunc;
