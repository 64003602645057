// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainProductGroupCard_maindiv__srqTM {
  margin: auto;
  margin-bottom: 15px;
  height: 3rem;
  min-width: 300px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  align-items: center;
  box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);
  border-radius: 6px;
  color: #0c2d57;
  cursor: pointer;
  background-color: #F4538A;
  line-height: 3rem;
}

.MainProductGroupCard_main_count_group__1BAh1 {
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/cards/MainProductGroupCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,gDAAgD;EAChD,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".maindiv {\n  margin: auto;\n  margin-bottom: 15px;\n  height: 3rem;\n  min-width: 300px;\n  width: fit-content;\n  text-align: center;\n  align-items: center;\n  box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.76);\n  border-radius: 6px;\n  color: #0c2d57;\n  cursor: pointer;\n  background-color: #F4538A;\n  line-height: 3rem;\n}\n\n.main_count_group {\n  font-size: 1.25rem;\n  font-weight: 700;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maindiv": `MainProductGroupCard_maindiv__srqTM`,
	"main_count_group": `MainProductGroupCard_main_count_group__1BAh1`
};
export default ___CSS_LOADER_EXPORT___;
