import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import SelectCountEvent from "./SelectCountEvent";

export const CountEventsList = () => {
  const allActiveCountsEvents = useSelector((state: RootState) => {
    return state.data.activeCountEvents;
  });

  console.log("Hallo from BroseAllCoupons, allcoupons: " + allActiveCountsEvents);

  return <Fragment>
  <SelectCountEvent countEventsList={allActiveCountsEvents} />
</Fragment>
}

