import {
  Typography,
  TextField,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonCo from "../modals/ButtonCo";
import AddUserFunc from "../functions/AddUserFunc";
import { CHUser, CountEvent } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import LoadingModal from "../modals/LoadingModal";
import SuccessModal from "../modals/SuccessModal";
import BasicModal from "../modals/BasicModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en-il";
import { extractSKUs, printSKUs } from "../functions/GeneralFunc";
import AddCountEventFunc from "../functions/AddCountEventFunc";
import { useState } from "react";
import AddItemsToCountEvent from "../functions/AddItemsToCountEvent";
import { setActiveCountEvents } from "../store/dataSlice";
import FetchAllActiveCountEvents from "../functions/FetchAllActiveCountEvents";

export default function AddCountEventForm() {
  console.log("Hello from AddCountEventForm");
  const [countName, setCountName] = useState("");
  const [countDate, setCountDate] = useState(dayjs());
  const [isActiveCount, setIsActiveCount] = useState(true);
  const [countItemsInput, setcountItemsInput] = useState("");
  const [countItemsList2, setCountItemsList2] = useState<string[]>([]);
  const [isNameValid, setisNameValid] = useState(false);
  const [isNameBlur, setisNameBlur] = useState(false);
  const [isErrorModalOpen, setisErrorModalOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingModalOpen, setisLoadingModalOpen] = useState(false);
  const [isSuccessModallOpen, setisSuccessModallOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allInputsValid = isNameValid;

  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue !== null) {
      setCountDate(newValue);
    }
  };

  const handleIsActiveChange = () => {
    setIsActiveCount(!isActiveCount);
  };

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newName = ev.target.value;
    if (newName === "") {
      setisNameValid(false);
    } else {
      setisNameValid(true);
    }
    setCountName(newName);
  };

  const handleItemsInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newItemList = ev.target.value;
    setcountItemsInput(newItemList);
  };

  const clearForm = () => {
    setCountName("");
    setCountDate(dayjs());
    setIsActiveCount(true);
  };

  function delay(ms: number | undefined) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const countEvent: CountEvent = {
    countEventId: 0,
    countEventName: countName,
    countEventDate: countDate.toDate(),
    itemsList: [],
    active: isActiveCount,
  };

  const handelOnSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (allInputsValid) {
      setisLoadingModalOpen(true);
      AddCountEventFunc({ countEvent, userToken }).then(async (res) => {
        setisLoadingModalOpen(false);
        if (!res.includes("Error") || res == "gotErr") {
          let countEventAdded: CountEvent = JSON.parse(res);
          let countEvenIdToAdd: number = countEventAdded.countEventId;
          let countItemsList: string[] = extractSKUs(countItemsInput);
          console.log("Count Event id = " + countEvenIdToAdd);
          console.log("items Sku = " + printSKUs(countItemsList));
          AddItemsToCountEvent({
            countEvenIdToAdd,
            countItemsList,
            userToken,
          }).then(async (res) => {
            setisLoadingModalOpen(false);
            if (!res.includes("Error") || res == "gotErr") {
              setisSuccessModallOpen(true);
              await delay(1500);
              setisSuccessModallOpen(false);
              let countEventAdded: CountEvent = JSON.parse(res);
              console.log("Count Event id = " + countEventAdded.countEventId);
              clearForm();
            }

            if (res.includes("Error")) {
              console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
              let error = JSON.parse(res);
              let errorMSG = error.value;
              setErrorTitle("Error");
              setErrorMessage(errorMSG);
              setisErrorModalOpen(true);
            }
            if (res == "Unauthorized") {
              console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
              let errorMSG = "Unauthorized Action";
              setErrorTitle("Unauthorized");
              setErrorMessage(errorMSG);
              setisErrorModalOpen(true);
            }
            if (res == "gotErr") {
              setErrorTitle("Server issues");
              setErrorMessage("Please try again in few minutes");
              setisErrorModalOpen(true);
              console.log(res);
            }
          });

          FetchAllActiveCountEvents(userToken).then((res2) => {
            if (res2 != "Error") {
              console.log("FetchAllClients " + res2);
              dispatch(setActiveCountEvents(JSON.parse(res2)));
            }
            if (res2 == "Error") {
              console.log(res2);
            }
          });
        }

        if (res.includes("Error")) {
          console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
          let error = JSON.parse(res);
          let errorMSG = error.value;
          setErrorTitle("Error");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "Unauthorized") {
          console.log("AddCompanyForm=>handelOnSubmit=> res=Error");
          let errorMSG = "Unauthorized Action";
          setErrorTitle("Unauthorized");
          setErrorMessage(errorMSG);
          setisErrorModalOpen(true);
        }
        if (res == "gotErr") {
          setErrorTitle("Server issues");
          setErrorMessage("Please try again in few minutes");
          setisErrorModalOpen(true);
          console.log(res);
        }
      });
    }
  };

  return (
    <div>
      <LoadingModal open={isLoadingModalOpen}></LoadingModal>
      <SuccessModal open={isSuccessModallOpen} />
      <BasicModal
        open={isErrorModalOpen}
        onClose={() => setisErrorModalOpen(false)}
        onConfirm={() => setisErrorModalOpen(false)}
        title={errorTitle}
        message={errorMessage}
      />
      <h1>צור ספירה חדשה</h1>
      <form>
        <TextField
          id="outlined-multiline-static"
          value={countName}
          error={isNameBlur && isNameValid === false}
          onBlur={() => setisNameBlur(true)}
          name="eventName"
          onChange={handleNameChange}
          style={{ width: "400px", margin: "25px" }}
          type="text"
          helperText="(נדרש)"
          label="תיאור הספירה"
          variant="outlined"
        />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-il">
          <DatePicker
            label="תאריך הספירה"
            value={countDate}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        <br />
        <br />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={isActiveCount}
              onChange={handleIsActiveChange}
              name="gilad"
            />
          }
          label="ספירה פעילה"
        />
        <br />
        <br />
        <TextField
          id="item-list-field"
          value={countItemsInput}
          label="מקטים לספירה"
          style={{ width: "400px", margin: "25px" }}
          multiline
          onChange={handleItemsInputChange}
          rows={8}
          defaultValue=""
          variant="outlined"
          helperText='(הדבק רשימה של מק"טים מעמודה של אקסל)'
        />
        <br />
        <br />
        <ButtonCo
          type={"submit"}
          onClick={handelOnSubmit}
          disabled={!allInputsValid}
        >
          צור ספירה חדשה
        </ButtonCo>
      </form>
    </div>
  );
}
