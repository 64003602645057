import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import UserMenuBar from "./UserMenuBar";
import {  useSelector } from "react-redux";
import { RootState } from "../store/store";
import SignInButton from "../button/SignInButton";
import { useNavigate } from "react-router-dom";
import UserAvatarMenu from "./UserAvatarMenu";
import UserMenuXs from "./UserMenuXs";
import { CheckActivity } from "../functions/CheckActivity";
import { useDispatch } from "react-redux";
import { logout } from "../store/userSlice";
import { clearAllDataLists } from "../store/dataSlice";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserFromRedux = useSelector((state: RootState) => {
    if (state.user.chUSER) {
      return state.user.chUSER;
    }
    return null;
  });

  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );
  /*
if (userToken!="") {
  CheckActivity(userToken); 
  }
*/
  let isOnLogin = false;
  let userName = UserFromRedux?.firstName;

  const loginOnClickHandler = () => {
    dispatch(logout());
    dispatch(clearAllDataLists());
    navigate("/login");
  };

  const logoOnClickHandler = () => {
    navigate("/");
  };

  const loginButtom = () => {
    if (isOnLogin) {
      return null;
    }

    if (userName == null) {
      return <SignInButton onClick={loginOnClickHandler}>Sign in</SignInButton>;
    } else {
      return (
        <>
          <UserMenuBar mode="md" />
          <UserAvatarMenu />
        </>
      );
    }
  };

  const loginButtomxs = () => {
    if (isOnLogin) {
      return null;
    }

    if (userName == null) {
      return <SignInButton onClick={loginOnClickHandler}>Sign in</SignInButton>;
    } else {
      return (
        <>
          <UserAvatarMenu />
          <UserMenuXs />
        </>
      );
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "#0c2d57",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 1,
            }}
          >
            <Avatar sx={{ height: "32px", width: "32px" }} src="icon32.png" />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              fontFamily: "rubik",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "#00bde0",
              textDecoration: "none",
            }}
            onClick={logoOnClickHandler}
          >
            Chelper
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          <Box
            sx={{
              flexGrow: 0,
              display: (theme) =>
                ({
                  xs: { xs: "none", md: "flex" },
                  md: { xs: "flex", md: "none" },
                }[theme.breakpoints.down("xs") ? "xs" : "md"]),
            }}
          >
            <>{loginButtom()}</>
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: (theme) =>
                ({
                  xs: { xs: "flex", md: "none" },
                  md: { xs: "none", md: "flex" },
                }[theme.breakpoints.down("xs") ? "xs" : "md"]),
            }}
          >
            <>{loginButtomxs()}</>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
