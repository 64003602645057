import React, { PropsWithChildren } from "react";
import classes from "./SignInButton.module.css";

type Props = {
  type?: "button" | "submit" | "reset"; // "?" means: the user don't have to provide type
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};
const SignInButton: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <button
      className={classes.button}
      type={props.type || "button"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default SignInButton;
