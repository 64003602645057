import { useEffect, useMemo } from "react";
import { Box, Fab, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useState } from "react";
import { useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { CHUser, CountEvent, CountLogDTO, Item } from "../tsClasses/CHTypes";
import { RootState } from "../store/store";
import DataGridDemo4Actions from "./CountLogDataFGridExtendedActions";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CHeader from "../button/CHeader";
import FetchAllCountEvents from "../functions/FetchAllCountEvents";
import moment from "moment";
import { heIL } from "@mui/x-data-grid/locales";
import CountEventsDataGridItemList from "./CountEventsDataGridItemList";

export default function CountEventsDataGrid() {
  const [rowId, setRowId] = useState(null);
  const [allCountsEvents, setAllCountsEvents] = useState<CountEvent[]>([]);
  const [itemsList, setitemsList] = useState<Item[]>([]);
  const [countName, setCountName] = useState("");

  const currentCount: CountEvent | null = useSelector(
    (state: RootState) => state.data.currentCount
  );
  const user: CHUser | null = useSelector(
    (state: RootState) => state.user.chUSER
  );
  const userToken: string | null = useSelector(
    (state: RootState) => state.user.userToken
  );

  useEffect(() => {
    console.log("inside the useffect");
    const fetchCounts = async () => {
      try {
        const counts = await FetchAllCountEvents(userToken);
        console.log(counts.at(0)?.countEventName);
        setAllCountsEvents(counts);
      } catch (error) {
        console.error("Error fetching count events:", error);
      }
    };

    fetchCounts();
  }, [userToken]);

  const initialRows: GridRowsProp = allCountsEvents.map((x) => ({
    id: x.countEventId,
    countName: x.countEventName,
    countDate: new Date(x.countEventDate),
    isCountActive: x.active,
    itemlist: x.itemsList,
  }));

  const [rows, setRows] = useState(initialRows);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "מזהה",
      width: 50,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countName",
      headerName: "שם הספירה",
      width: 350,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    {
      field: "countDate",
      headerName: "תאריך",
      width: 120,
      align: "right",
      editable: true,
      type: "date",
      renderCell: (params) => moment(params.row.countDate).format("DD/MM/YYYY"),
    },
    {
      field: "isCountActive",
      headerName: "ספירה פעילה",
      width: 100,
      align: "center",
      type: "boolean",
      editable: true,
    },
    {
      field: "update",
      headerName: "שמור",
      type: "actions",
      renderCell: (params) => (
        <DataGridDemo4Actions
          {...{ params, rowId, setRowId, userToken, user }}
        />
      ),
    },
    {
      field: "itemList",
      headerName: "רשימת פריטים",
      type: "actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: "relative",
          }}
        >
          <Fab
            sx={{
              width: 40,
              height: 40,
              boxShadow: 0,
            }}
            onClick={() => handleItemListOnClick(params.row)}
          >
            <ListAltIcon />
          </Fab>
        </Box>
      ),
    },
  ];

  const handleItemListOnClick = (params: any) => {
    setCountName(params.countName);
    setitemsList(params.itemlist);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(allCountsEvents);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "exportedData.xlsx");
  };

  useEffect(() => {
    setRows(initialRows);
  }, [allCountsEvents]);

  console.log("Initial rows:", initialRows);
  console.log("Rows:", rows);
  return (
    <div
      style={{
        maxWidth: "50%",
        padding: "0px",
        margin: "auto",
        direction: "rtl",
        textAlign: "right",
      }}
    >
      <CHeader type={1}>ניהול ספירות</CHeader>
      <img
        src="./images/icons/xls.png"
        style={{
          width: "50px",
          height: "55px",
          cursor: "pointer",
          padding: "15px",
        }}
        onClick={exportToExcel}
      />
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        autoHeight
        sx={{
          boxShadow: 2,
          align: "center",
          flexGrow: 1,
        }}
        processRowUpdate={(params) => setRowId(params.id)}
        editMode="row"
      />
      <CountEventsDataGridItemList
        itemsList={itemsList}
        countEventName={countName}
      />
    </div>
  );
}