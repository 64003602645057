import { CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

export default async function getAllCurrentCountLogsDTO(
  id: number,
  token: string
): Promise<CountLogDTO[] | null> {
  const url = apiPath + "/getAllCurrentCountLogsDTO";

  const headers = {
    "Content-Type": "application/json",
    id: id.toString(),
    token: token,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("getAllCurrentCountLogsDTO: Failed to fetch data ");
    }

    const data: CountLogDTO[] = await response.json();
    return data;
  } catch (error) {
    console.error("getAllCurrentCountLogsDTOError fetching data from :", error);
    
    return null;
  }
}
