// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorModal_backdrop__u6E3f {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.ErrorModal_modal__xenUS {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.ErrorModal_header__w70iw {
  background: #4f005f;
  padding: 1rem;
}

.ErrorModal_header__w70iw h2 {
  margin: 0;
  color: white;
}

.ErrorModal_content__085hv {
  padding: 1rem;
}

.ErrorModal_actions__R7HGD {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .ErrorModal_modal__xenUS {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/ErrorModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.modal {\n  position: fixed;\n  top: 30vh;\n  left: 10%;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n}\n\n.header {\n  background: #4f005f;\n  padding: 1rem;\n}\n\n.header h2 {\n  margin: 0;\n  color: white;\n}\n\n.content {\n  padding: 1rem;\n}\n\n.actions {\n  padding: 1rem;\n  display: flex;\n  justify-content: flex-end;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ErrorModal_backdrop__u6E3f`,
	"modal": `ErrorModal_modal__xenUS`,
	"header": `ErrorModal_header__w70iw`,
	"content": `ErrorModal_content__085hv`,
	"actions": `ErrorModal_actions__R7HGD`
};
export default ___CSS_LOADER_EXPORT___;
