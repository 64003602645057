import { CHUser, CountEvent, CountLogDTO } from "../tsClasses/CHTypes";
import { apiPath, getCurrentDateTime } from "./GeneralFunc";

type UpdateCountEventFuncProps = {
  countEventId: number;
  countEventName: string;
  countEventDate: Date | number;
  active: boolean;
  userToken: string;
};

const UpdateCountEventFunc = async (props: UpdateCountEventFuncProps) => {
  console.log("UpdateCountEventFunc: token" + props.userToken);

  const countEvent: CountEvent = {
    countEventId: props.countEventId,
    countEventName: props.countEventName,
    countEventDate: props.countEventDate,
    active: props.active,
    itemsList: []
  };

  const dataToSend = countEvent;

  console.log(dataToSend);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: props.userToken },
    body: JSON.stringify(dataToSend),
  };
  try {
    const response = await fetch(apiPath + "/updateCountEvent", requestOptions);
    if (response.status == 401) {
      console.log("UpdateCountEventFunc: Something went wrong!");
      throw new Error("Unauthorized");
       ;
    }
    if (response == null) {
      console.log("UpdateCountEventFunc: Something went wrong!");
      throw new Error ("Something went wrong...")
    }

    const result = await response.text();
    console.log("UpdateCountEventFunc RESULT: " + result);
    return result;
  } catch (error) {
    throw new Error ("Something went wrong..."+error)
  }
};

export default UpdateCountEventFunc;
