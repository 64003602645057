import { CHUser } from "../tsClasses/CHTypes";
import { apiPath } from "./GeneralFunc";

type AddUserFuncProps = {
  userToAdd : CHUser,
  userToken: string,
}

const AddUserFunc = async (props : AddUserFuncProps) => {
    console.log("Hello from AddUserFunc");
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: props.userToken },
      body: JSON.stringify(props.userToAdd),
    };

  
    try {
      const response = await fetch(
        apiPath+"/admin/addUser",
        requestOptions
      );
      if (response.status == 401) {
        console.log("AddUserFunc: Something went wrong!");
        return "Unauthorized";
      }
      if (response == null) {
        console.log("AddUserFunc: Something went wrong!");
      }
  
      const result = await response.text();
      console.log("AddUserFunc RESULT: " + result);
      return result;
    } catch (error) {
      return "gotErr";
    }
  };
  export default AddUserFunc;