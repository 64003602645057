import React, { PropsWithChildren, useState } from "react";
import classes from "./RadioGroupCo.module.css";

type Props = {
  name: string,
  options : string[],
  value: string | null,
  onChange:(value: string) => void,
};



const RadioGroupCo: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <div className={classes.main_div}>
    <div className={classes.radio}>
  {props.options.map((item, index) => (
    <React.Fragment key={index}>
      <input
        className={classes.radio__input}
        type="radio"
        value={item}
        name={props.name}
        id={props.name + item}
        checked={props.value==item}
        onChange={e=> props.onChange(e.target.value)}
      />
      <label className={classes.radio__label} htmlFor={props.name + item}>
        {item}
      </label>
    </React.Fragment>
  ))}
</div>
</div>

  );
};

export default RadioGroupCo;
